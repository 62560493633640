import {IIConProps} from '@common/interfaces';

export function ArrowLeftIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 12.843'>
      <path
        id='solid_arrow-left'
        data-name='solid arrow-left'
        d='M7.524,49.633l-.653.653a.7.7,0,0,1-1,0L.157,44.571a.7.7,0,0,1,0-1l5.717-5.717a.7.7,0,0,1,1,0l.653.653a.707.707,0,0,1-.012,1.009L3.969,42.9H15.244a.7.7,0,0,1,.706.706v.941a.7.7,0,0,1-.706.706H3.969l3.544,3.376a.7.7,0,0,1,.012,1.009Z'
        transform='translate(0.05 -37.65)'
        fill={fill}
      />
    </svg>
  );
}

ArrowLeftIcon.defaultProps = {
  fill: '#002662',
  width: 16,
  height: 10.333,
};
