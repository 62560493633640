import {IIConProps} from '@common/interfaces';

export function UserProfile({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 54 54'>
      <g
        id='Group_12909'
        data-name='Group 12909'
        transform='translate(21494 -3576)'>
        <circle
          id='Ellipse_148'
          data-name='Ellipse 148'
          cx='27'
          cy='27'
          r='27'
          transform='translate(-21494 3576)'
          fill='#efece8'
        />
        <path
          id='Intersection_1'
          data-name='Intersection 1'
          d='M5.605,43.472A21.753,21.753,0,0,1,20.577,27.931a11,11,0,1,1,12.847,0A21.756,21.756,0,0,1,48.4,43.472a27,27,0,0,1-42.79,0Z'
          transform='translate(-21494 3576)'
          fill='#c6c3bd'
        />
      </g>
    </svg>
  );
}

UserProfile.defaultProps = {
  fill: '#efece8',
  width: '54',
  height: '54',
};
