import {Api} from '@common/constants/index';
import store, {dispatch} from '@store';
import axiosInstance, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {authActions} from '../../pages/Auth/store/reducer';
import {BASE_URL, EXIM_BASE_URL} from '../helpers/constants';
import {API_CONSTANTS, coreActions} from '../store/loadingReducer';
import {alertActions} from './store/alertReducer';

const axios = axiosInstance.create({
  baseURL: BASE_URL,
});

export type API_KEYS = keyof typeof API_CONSTANTS;

export interface ICustomError extends AxiosError {
  'error-code': string;
  'error-message': string;
}

export interface AxiosRequestConfigWithParams extends AxiosRequestConfig {
  API?: API_KEYS;
}

const handleTopLoader = (isLoading: boolean) => {
  dispatch(coreActions.handleIsLoading(isLoading));
};

const getErrorMessage = (errorObj: AxiosError<unknown, string>) => {
  let errorCode = '';
  let errorMessage = '';
  if (errorObj.response?.data) {
    const responseData = errorObj.response.data as ICustomError;
    errorCode = responseData['error-code'];
    errorMessage = responseData['error-message'];
    // Logout automatically if session expired
    if (
      errorMessage === 'Please login' ||
      errorMessage === 'Access Forbidden' ||
      errorMessage === 'You have logged in from another Session' ||
      errorMessage === 'Your session has timed out. Please login again.'
    ) {
      sessionStorage.clear();
      setTimeout(() => window.location.reload(), 2000);
    }
  }
  return {errorCode, errorMessage};
};

// TODO: Keeping below code for reference and Commenting because we are adding the interceptor based-on URL
// axios?.interceptors.request.use(
//   (config: AxiosRequestConfigWithParams) => {
//     // const {API} = config;
//     // const needsLoader = API
//     // ? store.getState().loader.appLoading[API as API_KEYS]
//     // : false;
//     const sessionToken = StorageHelper.getItem(STORAGE.SESSION_TOKEN);
//     if (config.headers) {
//       if (sessionToken) {
//         config.headers.sessionToken = sessionToken;
//         config.headers.userID = StorageHelper.getItem(STORAGE.USER_ID);
//       }
//     }
//     handleTopLoader(true);
//     return config;
//   },
//   (error: AxiosError) => {
//     const {errorCode, errorMessage} = getErrorMessage(error);
//     dispatch(
//       alertActions.setAlertMsg({
//         code: errorCode || error.code,
//         message: errorMessage || error.message,
//         alertType: 'danger',
//       })
//     );
//     handleTopLoader(false);
//     return Promise.reject(error);
//   }
// );

let isRefreshing = false;
let failedQueue: unknown[] = [];

const processQueue = (error: unknown, token = null) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  failedQueue.forEach((promise: any) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};
// TODO: Keeping below code for reference and Commenting because we are adding the interceptor based-on URL
// axios?.interceptors.response.use(
//   (response: AxiosResponse) => {
//     handleTopLoader(false);
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     const {errorCode, errorMessage} = getErrorMessage(error);
//     dispatch(
//       alertActions.setAlertMsg({
//         code: errorCode || error.code,
//         message: errorMessage || error.message,
//         alertType: 'danger',
//       })
//     );
//     // if (originalRequest.url === '') {
//     //   handleTopLoader(false);
//     //   return Promise.reject(error);
//     // }
//     if (error?.response?.status === 403) {
//       handleTopLoader(false);
//       dispatch(authActions.logout());
//       return Promise.reject(error);
//     }
//     // eslint-disable-next-line no-underscore-dangle
//     if (error?.response?.status === 401 && !originalRequest._retry) {
//       if (originalRequest.url === '/auth/refresh') {
//         processQueue(error, null);
//         handleTopLoader(false);
//         // type here should be session expired
//         return Promise.reject(error);
//       }
//       if (isRefreshing) {
//         return new Promise((resolve, reject) => {
//           failedQueue.push({resolve, reject});
//         })
//           .then((token) => {
//             originalRequest.headers.Authorization = `Bearer ${token}`;
//             handleTopLoader(false);
//             return axios(originalRequest);
//           })
//           .catch((isRefreshingError) => {
//             handleTopLoader(false);
//             return Promise.reject(isRefreshingError);
//           });
//       }

//       // eslint-disable-next-line no-underscore-dangle
//       originalRequest._retry = true;
//       isRefreshing = true;

//       return new Promise((resolve, reject) => {
//         axiosInstance
//           .get(`/auth/refresh`)
//           .then(({data}: AxiosResponse) => {
//             // set access token from response
//             axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
//             originalRequest.headers.Authorization = `Bearer ${data.data.token}`;
//             dispatch(authActions.setAccessToken(data.data.token));
//             dispatch(authActions.setRefreshToken(data.data.refreshToken));
//             processQueue(null, data.data.token);
//             StorageHelper.setItem(STORAGE.ACCESS_TOKEN, data.data.token);
//             StorageHelper.setItem(
//               STORAGE.REFRESH_TOKEN,
//               data.data.refreshToken
//             );
//             handleTopLoader(false);
//             resolve(axiosInstance(originalRequest));
//           })
//           .catch((refreshErr: Error | AxiosResponse) => {
//             processQueue(refreshErr, null);

//             handleTopLoader(false);
//             // dispatch session expired
//             // dispatch({
//             //   type: AUTH_ACTIONS.SESSION_EXPIRED,
//             // });
//             // dispatch({
//             //   type: CORE_ACTIONS.ENABLE_MODAL,
//             //   payload: {
//             //     message: 'Please login again',
//             //     severity: 'error',
//             //     alertTitle: 'Session expired'
//             //   }
//             // });
//             StorageHelper.clear();
//             dispatch(authActions.logout());
//             reject(refreshErr);
//           })
//           .then(() => {
//             isRefreshing = false;
//           });
//       });
//     }
//     handleTopLoader(false);
//     if (error.response.data) {
//       // dispatch store for enabling modal
//     }
//     return Promise.reject(error);
//   }
// );

function throwError(e: {response: unknown}) {
  throw e.response;
}

// Initialize counter to keep track of pending requests
let requestCounter = 0;

function getInstance(url: string) {
  const axiosInstanceForRequest = axiosInstance.create({
    baseURL:
      (url.includes(Api.EXIM) || url.includes(Api.MOOWR_SERVICE)) &&
      !url.includes(Api.EXIM_SUBSCRIPTION_SERVICE)
        ? EXIM_BASE_URL
        : BASE_URL,
  });

  // Adding interceptor here because we have 2 URLs
  axiosInstanceForRequest?.interceptors.request.use(
    (config: AxiosRequestConfigWithParams) => {
      // const {API} = config;
      // const needsLoader = API
      // ? store.getState().loader.appLoading[API as API_KEYS]
      // : false;
      const sessionToken = store.getState().auth.accessToken;
      const userId = store.getState().auth.userData.userID;
      if (config.headers) {
        if (sessionToken) {
          config.headers.sessionToken = sessionToken;
          config.headers.userId = userId;
          config.headers.Accept = 'application/json';
        }
      }
      // Increment the counter to track the total request
      requestCounter += 1;
      handleTopLoader(true);
      return config;
    },
    (error: AxiosError) => {
      const {errorCode, errorMessage} = getErrorMessage(error);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || error.code,
          message: errorMessage || error.message,
          alertType: 'danger',
        })
      );
      handleTopLoader(false);
      return Promise.reject(error);
    }
  );

  axiosInstanceForRequest?.interceptors.response.use(
    (response: AxiosResponse) => {
      requestCounter -= 1;
      // Hide loader if all requests have been resolved
      if (requestCounter === 0) {
        handleTopLoader(false);
      }
      return response;
    },
    async (error) => {
      requestCounter -= 1;
      const originalRequest = error.config;
      const {errorCode, errorMessage} = getErrorMessage(error);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || error.code,
          message: errorMessage || error.message,
          alertType: 'danger',
        })
      );
      // if (originalRequest.url === '') {
      //   handleTopLoader(false);
      //   return Promise.reject(error);
      // }
      if (error?.response?.status === 403) {
        handleTopLoader(false);
        dispatch(authActions.logout());
        return Promise.reject(error);
      }
      // eslint-disable-next-line no-underscore-dangle
      if (error?.response?.status === 401 && !originalRequest._retry) {
        if (originalRequest.url === '/auth/refresh') {
          processQueue(error, null);
          handleTopLoader(false);
          // type here should be session expired
          return Promise.reject(error);
        }
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({resolve, reject});
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              handleTopLoader(false);
              return axiosInstanceForRequest(originalRequest);
            })
            .catch((isRefreshingError) => {
              handleTopLoader(false);
              return Promise.reject(isRefreshingError);
            });
        }

        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`/auth/refresh`)
            .then(({data}: AxiosResponse) => {
              // set access token from response
              axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
              originalRequest.headers.Authorization = `Bearer ${data.data.token}`;
              dispatch(authActions.setAccessToken(data.data.token));
              dispatch(authActions.setRefreshToken(data.data.refreshToken));
              processQueue(null, data.data.token);
              handleTopLoader(false);
              resolve(axiosInstance(originalRequest));
            })
            .catch((refreshErr: Error | AxiosResponse) => {
              processQueue(refreshErr, null);

              handleTopLoader(false);
              // dispatch session expired
              // dispatch({
              //   type: AUTH_ACTIONS.SESSION_EXPIRED,
              // });
              // dispatch({
              //   type: CORE_ACTIONS.ENABLE_MODAL,
              //   payload: {
              //     message: 'Please login again',
              //     severity: 'error',
              //     alertTitle: 'Session expired'
              //   }
              // });
              dispatch(authActions.logout());
              reject(refreshErr);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
      handleTopLoader(false);
      if (error.response.data) {
        // dispatch store for enabling modal
      }
      return Promise.reject(error);
    }
  );

  return axiosInstanceForRequest;
}

export async function get(url: string, data?: object) {
  try {
    const resp = await getInstance(url).get(url, data);
    return resp.data;
  } catch (e) {
    return throwError(e as {response: unknown});
  }
}

export async function post(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<unknown> {
  try {
    const res = await getInstance(url).post(url, data, config);
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

// keep below underscore-dangle off for only this function as the name
// overlaps with the delete keyword in javascript
// eslint-disable-next-line no-underscore-dangle
export async function _delete(url: string, data?: unknown): Promise<unknown> {
  try {
    const res = await getInstance(url).delete(
      url,
      data as AxiosRequestConfig<unknown> | undefined
    );
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

export async function put(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<unknown> {
  try {
    const res = await getInstance(url).put(url, data, config);
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

export async function patch(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<unknown> {
  try {
    const res = await getInstance(url).patch(url, data, config);
    return res.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

export default axios;
