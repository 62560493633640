import {IIConProps} from '@common/interfaces';

export function CheckIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g id='Group_897' data-name='Group 897' transform='translate(-750 -1700)'>
        <g id='Group_701' data-name='Group 701' transform='translate(-100)'>
          <circle
            id='Ellipse_33'
            data-name='Ellipse 33'
            cx='12'
            cy='12'
            r='12'
            transform='translate(850 1700)'
            fill={fill}
          />
          <path
            id='solid_check'
            data-name='solid check'
            d='M4.835,74.637.209,70.4a.615.615,0,0,1,0-.923l1.006-.923a.758.758,0,0,1,1.006,0l3.116,2.856,6.675-6.118a.758.758,0,0,1,1.006,0l1.006.923a.615.615,0,0,1,0,.923l-8.184,7.5a.758.758,0,0,1-1.006,0Z'
            transform='translate(855 1641.902)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}

CheckIcon.defaultProps = {
  fill: '#002662',
  width: '28',
  height: '28',
};
