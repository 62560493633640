import headerReducer from '@common/store/headerSlice';
import alertReducer from '@core/api/store/alertReducer';
import {decrypt, encrypt} from '@core/helpers/encryption';
import loadingReducer from '@core/store/loadingReducer';
import themeReducer from '@core/theme/store/reducer';
import authReducer from '@pages/Auth/store/reducer';
import dashboardReducer from '@pages/Dashboard/store/reducer';
import dataExtractorReducer from '@pages/DataExtractor/store/reducer';
import dutyDrawbackReducer from '@pages/DutyDrawback/store/reducer';
import moowrReducer from '@pages/Moowr/store/reduce';
import profileReducer from '@pages/Profile/store/reducer';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import subscriptionReducer from '@subscription/store/reducer';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const rootReducer = combineReducers({
  alert: alertReducer,
  theme: themeReducer,
  loader: loadingReducer,
  auth: authReducer,
  header: headerReducer,
  dashboard: dashboardReducer,
  dataExtractor: dataExtractorReducer,
  dutyDrawback: dutyDrawbackReducer,
  moowr: moowrReducer,
  subscription: subscriptionReducer,
  profile: profileReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader', 'alert'],
  transforms: [
    {
      in: (state: typeof rootReducer) => {
        const stateString = JSON.stringify(state);
        return encrypt(stateString);
      },
      out: (stateString: string) => {
        const decryptedState = JSON.parse(decrypt(stateString));
        return {...decryptedState};
      },
    },
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const {dispatch} = store;

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export default store;
