import {IIConProps} from '../utils/interface';

export function ArrowRight({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 43.996 21.004'>
      <path
        id='Union_13'
        data-name='Union 13'
        d='M18663,21469h-32v-7h32v-7l12,10.5-12,10.5Z'
        transform='translate(-18631.002 -21454.998)'
        fill={fill}
      />
    </svg>
  );
}

ArrowRight.defaultProps = {
  fill: '#207245',
  width: '43',
  height: '21',
};
