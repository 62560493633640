import {IIConProps} from '../utils/interface';

export function ManageSubscription({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16.002 15.998'>
      <path
        id='Union_2'
        data-name='Union 2'
        d='M15596.6-11000a1.6,1.6,0,0,1-1.6-1.6v-6.4a1.606,1.606,0,0,1,1.6-1.6h12.8a1.605,1.605,0,0,1,1.6,1.6v6.4a1.6,1.6,0,0,1-1.6,1.6Zm0-11.2v-1.6h12.8v1.6Zm1.6-3.2v-1.6h9.6v1.6Z'
        transform='translate(-15594.998 11016)'
        fill={fill}
      />
    </svg>
  );
}

ManageSubscription.defaultProps = {
  fill: '#5478b5',
  width: '21',
  height: '15',
};
