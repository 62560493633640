import {IIConProps} from '@common/interfaces';

export function Message(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 12'>
      <path
        id='solid_envelope'
        data-name='solid envelope'
        d='M15.7,67.963a.188.188,0,0,1,.3.147V74.5A1.5,1.5,0,0,1,14.5,76H1.5A1.5,1.5,0,0,1,0,74.5V68.113a.187.187,0,0,1,.3-.147c.7.544,1.628,1.234,4.816,3.55C5.778,72,6.891,73.009,8,73c1.116.009,2.25-1.025,2.884-1.488C14.072,69.2,15,68.506,15.7,67.963ZM8,72c.725.012,1.769-.913,2.294-1.294,4.147-3.009,4.463-3.272,5.419-4.022A.748.748,0,0,0,16,66.094V65.5A1.5,1.5,0,0,0,14.5,64H1.5A1.5,1.5,0,0,0,0,65.5v.594a.752.752,0,0,0,.288.591c.956.747,1.272,1.012,5.419,4.022C6.231,71.088,7.275,72.013,8,72Z'
        transform='translate(0 -64)'
        fill={fill}
      />
    </svg>
  );
}

Message.defaultProps = {
  fill: 'white',
  width: '16',
  height: '16',
};
