import {
  IGstDetails,
  ISubscriptionAddon,
  OrganizationDetails,
  PartnerDetails,
  ProductDetails,
} from '@common/interfaces';
import {createSlice} from '@reduxjs/toolkit';

// Organization Details

interface IInitialState {
  panNumber: string;
  gstInDetails: IGstDetails;
  subscriptionProductTitle: string;
  organizationDetails: OrganizationDetails;
  partnerDetails: PartnerDetails;
  productDetails: ProductDetails;
  subscriptionType: {
    subscribeAs: string;
    type: string;
  };
  isAddMoreGstinClicked: boolean;
  subscriptionSummaryAddon: ISubscriptionAddon[];
  recordCountDetails: string;
  existingPlanName: string;
  existingPlanStatus: string;
  isRenewOrUpgradePlan: boolean;
}

export const emptyGstInDetails = {
  businessName: '',
  taxPayerType: '',
  tradeName: '',
  status: '',
  gstin: '',
  city: '',
  stj: '',
  constitutionOfBusiness: '',
  registrationDate: '',
  panNumber: '',
};

export const initialState: IInitialState = {
  subscriptionProductTitle: '',
  subscriptionType: {subscribeAs: '', type: ''},
  isAddMoreGstinClicked: false,
  subscriptionSummaryAddon: [],
  recordCountDetails: '',
  panNumber: '',
  gstInDetails: emptyGstInDetails,
  organizationDetails: {
    gstinDetails: {
      gstin: '',
      legalName: '',
      tradeName: '',
    },
    iecDetails: {
      iceGatePassword: '',
      iceGateUserName: '',
      iecCode: '',
    },
    orgName: '',
    pan: '',
  },
  partnerDetails: {
    partnerCode: '',
  },
  productDetails: {
    addonDetails: [],
    planDetails: {
      basePrice: 0,
      code: '',
      description: '',
      name: '',
      productName: '',
    },
    productName: 'Data Extractor',
  },
  existingPlanName: '',
  existingPlanStatus: '',
  isRenewOrUpgradePlan: false,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setPanNumber(state, actions) {
      state.panNumber = actions.payload;
    },
    getGstInDetails(state, actions) {
      state.gstInDetails = actions.payload;
    },
    addOrganizationDetails(state, action) {
      state.organizationDetails.iecDetails = action.payload;
    },
    addGstDetails(state, action) {
      state.organizationDetails.gstinDetails = action.payload;
    },
    addPanNumberAndOrgName(state, action) {
      const {panId, lgnm} = action.payload;
      state.organizationDetails.pan = panId;
      state.organizationDetails.orgName = lgnm;
    },
    setSubscriptionProduct(state, action) {
      state.productDetails.productName = action.payload;
    },
    setPartnerCode(state, action) {
      state.partnerDetails.partnerCode = action.payload;
    },
    resetOrganizationDetails(state) {
      state.organizationDetails = initialState.organizationDetails;
    },
    resetAllDetails(state) {
      state.organizationDetails = initialState.organizationDetails;
      state.partnerDetails = initialState.partnerDetails;
    },
    setSubscriptionAllAddons(state, action) {
      state.subscriptionSummaryAddon = action.payload;
    },
    setSubscriptionPlanData(state, action) {
      state.productDetails.planDetails = action.payload;
    },
    setIceCode(state, action) {
      state.organizationDetails.iecDetails.iecCode = action.payload;
    },
    setExistingPlanDetails(state, action) {
      const {planName, paymentStatus} = action.payload;
      state.existingPlanName = planName;
      state.existingPlanStatus = paymentStatus;
    },
    setIsRenewOrUpgradePlan(state, action) {
      state.isRenewOrUpgradePlan = action.payload;
    },
  },
});

const subscriptionReducer = subscriptionSlice.reducer;

export const subscriptionActions = subscriptionSlice.actions;
export default subscriptionReducer;
