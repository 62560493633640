import {
  NavigatePageName,
  PageType,
  Path,
  ProfileTabs,
  SupportContact,
} from '@common/constants';
import {
  ClaimReportIcon,
  DataParsingSetupIcon,
  FeedbackContact,
  Message,
  NotificationActive,
  Setting,
  Telephone,
} from '@shared/icons';
import {RootState} from '@store';
import {ReactNode} from 'react';
import {useSelector} from 'react-redux';

export interface IDropDownValues {
  id: string;
  value: ReactNode;
  path: string;
  listTitle?: string;
  icon?: ReactNode;
}

export interface IData {
  id: string;
  title: ReactNode;
  path?: string;
  hasDropdown: boolean;
  hasActiveDropdown?: boolean;
  reference?: string;
  dropDownValues?: IDropDownValues[];
}

interface IMenuData {
  businessName?: string;
  pageName?: PageType;
}

interface IStaticData {
  isAuthenticated?: boolean;
}

const {PROFILE, FEEDBACK_CONTACT_US} = Path;

export function StaticData(props: IStaticData): IData[] {
  const {isAuthenticated} = props;
  const {
    auth: {userData},
  } = useSelector((state: RootState) => state);
  if (isAuthenticated) {
    const {firstName, lastName} = userData;
    const userName = [firstName, lastName].join(' ');

    return [
      {
        id: '1',
        title: <NotificationActive fill='#B5BAD3' />,
        path: '/',
        hasDropdown: false,
      },
      {
        id: '2',
        title: <Setting fill='#B5BAD3' />,
        hasDropdown: false,
        reference: 'settings',
        hasActiveDropdown: false,
        dropDownValues: [
          {
            id: '1',
            value: 'Feedback / Contact Us',
            path: `${PROFILE}${FEEDBACK_CONTACT_US}`,
            listTitle: 'support',
            icon: <FeedbackContact />,
          },
          {
            id: '2',
            value: 'Browse Plugins',
            path: '/',
            listTitle: 'plugins',
            icon: <Setting fill='black' height={12} width={12} />,
          },
          {
            id: '3',
            value: 'Manage Plugins',
            path: '/',
            listTitle: 'plugins',
            icon: <Setting fill='black' height={12} width={12} />,
          },
          {
            id: '4',
            value: ProfileTabs.CLAIM_REPORTS,
            path: `${Path.PROFILE}${Path.CLAIM_REPORTS}`,
            listTitle: 'duty-drawback',
            icon: <ClaimReportIcon />,
          },
          {
            id: '5',
            value: ProfileTabs.DATA_PARSING_SETUP,
            path: `${Path.PROFILE}${Path.DATA_PARSING_SETUP}`,
            listTitle: 'data-extractor',
            icon: <DataParsingSetupIcon />,
          },
        ],
      },
      {
        id: '3',
        title: userName,
        hasDropdown: true,
        hasActiveDropdown: false,
        reference: 'userDetails',
      },
    ];
  }
  return [
    {
      id: 'mail',
      title: (
        <div className='icon-text-center'>
          <span className='support-contact-icon'>
            <Message height={16} width={16} />
          </span>
          <span className='support-contact'>{SupportContact.mail}</span>
          <span className='support-contact-pipe'>|</span>
        </div>
      ),
      reference: 'login',
      path: `mailto:${SupportContact.mail}`,
      hasDropdown: false,
    },
    {
      id: 'phone',
      title: (
        <div className='icon-text-center'>
          <span className='support-contact-icon'>
            <Telephone fill='white' height={17} width={16} />
          </span>
          <span className='support-contact'>{SupportContact.phone}</span>
        </div>
      ),
      reference: 'login',
      path: `tel:${SupportContact.phone}`,
      hasDropdown: false,
    },
  ];
}

StaticData.defaultProps = {
  isAuthenticated: false,
};

export function MenuData(props: IMenuData): IData[] {
  const {pageName} = props;

  const {DATA_EXTRACTOR, DUTY_DRAWBACK} = NavigatePageName;

  const dashboard = {
    id: '1',
    title: 'Dashboard',
    hasDropdown: false,
    hasActiveDropdown: false,
    path: Path.DASHBOARD,
  };

  switch (pageName) {
    case DATA_EXTRACTOR:
      return [
        dashboard,
        {
          id: '2',
          title: 'Business Contacts',
          hasDropdown: false,
          hasActiveDropdown: false,
        },
      ];

    case DUTY_DRAWBACK:
      return [
        dashboard,
        {
          id: '2',
          title: 'Business Contacts',
          hasDropdown: false,
          hasActiveDropdown: false,
        },
      ];

    default: // default send by dashboard
      return [
        {
          id: '11',
          title: 'Dashboard',
          hasActiveDropdown: false,
          hasDropdown: false,
          path: Path.DASHBOARD,
        },
      ];
  }
}

MenuData.defaultProps = {
  businessName: '',
  pageName: 'DASHBOARD',
};
