import {IIConProps} from '@common/interfaces';

export function EditIcon(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_7397'
        data-name='Group 7397'
        transform='translate(-933 -1650)'>
        <g id='Group_892' data-name='Group 892'>
          <g id='Group_854' data-name='Group 854' transform='translate(-70)'>
            <rect
              id='Rectangle_2126'
              data-name='Rectangle 2126'
              width='24'
              height='24'
              rx='5'
              transform='translate(1003 1650)'
              fill='#c2e2e3'
            />
            <path
              id='solid_pen'
              data-name='solid pen'
              d='M6.8,2.193l3,3L3.289,11.708.614,12a.563.563,0,0,1-.621-.621l.3-2.677Zm4.856-.447L10.251.337a1.126,1.126,0,0,0-1.592,0L7.334,1.662l3,3L11.66,3.337a1.126,1.126,0,0,0,0-1.592Z'
              transform='translate(1009.011 1655.993)'
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

EditIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#002662',
};
