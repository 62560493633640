import {IIConProps} from '@common/interfaces';

export function SendMessageIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_13530'
        data-name='Group 13530'
        transform='translate(5007 -12363)'>
        <g
          id='Group_4435'
          data-name='Group 4435'
          transform='translate(-6093 12042)'>
          <g
            id='Group_4411'
            data-name='Group 4411'
            transform='translate(1086 321)'>
            <g id='Group_4401' data-name='Group 4401'>
              <rect
                id='Rectangle_26'
                data-name='Rectangle 26'
                width='24'
                height='24'
                rx='5'
                fill='#cbe5e6'
              />
            </g>
          </g>
        </g>
        <g
          id='Group_13529'
          data-name='Group 13529'
          transform='translate(0 -15)'>
          <path
            id='Path_5831'
            data-name='Path 5831'
            d='M-4996.547,12392.234l9.531-9.547s-5.61,14.389-6,15.007-.822.091-.934-.122S-4996.547,12392.234-4996.547,12392.234Z'
            fill={fill}
          />
          <path
            id='Path_5832'
            data-name='Path 5832'
            d='M-4987.016,12392.234l-9.531-9.547s5.61,14.389,6,15.007.822.091.934-.122S-4987.016,12392.234-4987.016,12392.234Z'
            transform='translate(7394.984 17378.547) rotate(90)'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

SendMessageIcon.defaultProps = {
  fill: '#002662',
  width: '24',
  height: '24',
};
