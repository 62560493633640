import {IIConProps} from '../utils/interface';

export function Calendar({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14 16'>
      <path
        id='regular_calendar-alt'
        data-name='regular calendar-alt'
        d='M4.625,9H3.375A.376.376,0,0,1,3,8.625V7.375A.376.376,0,0,1,3.375,7h1.25A.376.376,0,0,1,5,7.375v1.25A.376.376,0,0,1,4.625,9ZM8,8.625V7.375A.376.376,0,0,0,7.625,7H6.375A.376.376,0,0,0,6,7.375v1.25A.376.376,0,0,0,6.375,9h1.25A.376.376,0,0,0,8,8.625Zm3,0V7.375A.376.376,0,0,0,10.625,7H9.375A.376.376,0,0,0,9,7.375v1.25A.376.376,0,0,0,9.375,9h1.25A.376.376,0,0,0,11,8.625Zm-3,3v-1.25A.376.376,0,0,0,7.625,10H6.375A.376.376,0,0,0,6,10.375v1.25A.376.376,0,0,0,6.375,12h1.25A.376.376,0,0,0,8,11.625Zm-3,0v-1.25A.376.376,0,0,0,4.625,10H3.375A.376.376,0,0,0,3,10.375v1.25A.376.376,0,0,0,3.375,12h1.25A.376.376,0,0,0,5,11.625Zm6,0v-1.25A.376.376,0,0,0,10.625,10H9.375A.376.376,0,0,0,9,10.375v1.25A.376.376,0,0,0,9.375,12h1.25A.376.376,0,0,0,11,11.625ZM14,3.5v11A1.5,1.5,0,0,1,12.5,16H1.5A1.5,1.5,0,0,1,0,14.5V3.5A1.5,1.5,0,0,1,1.5,2H3V.375A.376.376,0,0,1,3.375,0h1.25A.376.376,0,0,1,5,.375V2H9V.375A.376.376,0,0,1,9.375,0h1.25A.376.376,0,0,1,11,.375V2h1.5A1.5,1.5,0,0,1,14,3.5ZM12.5,14.313V5H1.5v9.313a.188.188,0,0,0,.188.188H12.313A.188.188,0,0,0,12.5,14.313Z'
        fill={fill}
      />
    </svg>
  );
}

Calendar.defaultProps = {
  fill: '#fff',
  width: '14',
  height: '16',
};
