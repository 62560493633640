import {IIConProps} from '../utils/interface';

export function FeedbackContact({fill, width, height}: IIConProps) {
  return (
    <svg
      id='circle_notifications_black_24dp'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 21 21'>
      <path id='Path_915' data-name='Path 915' d='M0,0H21V21H0Z' fill='none' />
      <g id='noun-support-1309030' transform='translate(-137.979 -67.979)'>
        <path
          id='Path_3441'
          data-name='Path 3441'
          d='M221.3,145.652a5.652,5.652,0,1,0-5.652,5.652A5.659,5.659,0,0,0,221.3,145.652Z'
          transform='translate(-67.174 -67.174)'
          fill={fill}
        />
        <path
          id='Path_3442'
          data-name='Path 3442'
          d='M148.479,70a7.781,7.781,0,0,0-7.772,7.772H140V81.3h2.12V77.772a6.359,6.359,0,1,1,12.718,0v3.24l-4.532,4.532h-3.24v1.413h3.825l5.36-5.36v-.293h.707V77.772h-.707A7.781,7.781,0,0,0,148.478,70Z'
          transform='translate(0 0)'
          fill={fill}
        />
      </g>
    </svg>
  );
}

FeedbackContact.defaultProps = {
  fill: '#5478b5',
  width: '21',
  height: '21',
};
