import {ReactComponent as EximLogoLight} from '@assets/EximImages/EximLogoLight.svg';
import Menu from '@common/components/Navbar/components/Menu';
import {
  MenuData,
  StaticData,
} from '@common/components/Navbar/components/Menu/config';
import {PageType, Path} from '@common/constants';
import {memo} from 'react';
import {Link} from 'react-router-dom';

import './index.scss';

interface INavbarProps {
  pageType?: PageType;
  isLoggedIn?: boolean;
  businessName?: string;
}

function Navbar(props: INavbarProps) {
  const {pageType, isLoggedIn, businessName} = props;

  return (
    <div className='header-wrapper' data-testid='header-wrapper'>
      <nav>
        <ul className='header-container'>
          <li>
            <div className='logo'>
              <Link to={isLoggedIn ? Path.DASHBOARD : '#'}>
                <EximLogoLight />
              </Link>
            </div>
          </li>
          {isLoggedIn ? (
            <div className='middle-part'>
              <Menu
                data={MenuData({
                  businessName,
                  pageName: pageType,
                })}
              />
            </div>
          ) : (
            ''
          )}

          <div className='right-part'>
            <div
              className={`${isLoggedIn ? 'authenticated' : 'unauthenticated'}`}>
              <Menu
                data={StaticData({
                  isAuthenticated: isLoggedIn as boolean,
                })}
              />
            </div>
          </div>
        </ul>
      </nav>
    </div>
  );
}
Navbar.defaultProps = {
  isLoggedIn: false,
  businessName: '',
  pageType: 'DASHBOARD',
};
export default memo(Navbar);
