import {IIConProps} from '@common/interfaces';

export function NotificationActive(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16.4 17.801'>
      <g
        id='Group_13070'
        data-name='Group 13070'
        transform='translate(-1213.6 -17.201)'>
        <path
          id='Icon_material-notifications'
          data-name='Icon material-notifications'
          d='M13.3,21.55a1.831,1.831,0,0,0,1.826-1.826H11.477A1.825,1.825,0,0,0,13.3,21.55Zm5.477-5.477V11.509c0-2.8-1.5-5.148-4.108-5.769V5.119a1.369,1.369,0,1,0-2.738,0V5.74c-2.62.621-4.108,2.958-4.108,5.769v4.564L6,17.9v.913H20.6V17.9Z'
          transform='translate(1207.6 13.45)'
          fill={fill}
        />
        <circle
          id='Ellipse_92'
          data-name='Ellipse 92'
          cx='3'
          cy='3'
          r='3'
          transform='translate(1224 18)'
          fill='red'
        />
      </g>
    </svg>
  );
}

NotificationActive.defaultProps = {
  color: 'white',
  width: '18',
  height: '18',
};
