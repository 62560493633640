import {IIConProps} from '@common/interfaces';

export function BellIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10 18.798'>
      <g
        id='Group_9401'
        data-name='Group 9401'
        transform='translate(-1485 -9828)'>
        <circle
          id='Ellipse_157'
          data-name='Ellipse 157'
          cx='5'
          cy='5'
          r='5'
          transform='translate(1485 9828)'
          fill={fill}
        />
        <path
          id='Path_1223'
          data-name='Path 1223'
          d='M0,0H1.728V6L.915,7.8,0,6Z'
          transform='translate(1489.136 9839)'
          fill={fill}
        />
        <g
          id='Path_1224'
          data-name='Path 1224'
          transform='translate(1486 9829)'
          fill='none'>
          <path
            d='M4,0C1.694,1.33,1.3,1.291,0,4A4,4,0,0,1,4,0Z'
            stroke='none'
          />
          <path
            d='M 4 0 C 1.694089889526367 1.330080032348633 1.29967999458313 1.291019916534424 0 4 C 0 1.790859937667847 1.790859937667847 0 4 0 Z'
            stroke='none'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}

BellIcon.defaultProps = {
  fill: '#0e3668',
  width: '10',
  height: '18.8',
};
