import {IUserData} from '@common/interfaces';
import {createSlice} from '@reduxjs/toolkit';

interface IAuthGenerics<T> {
  userData: T;
  accessToken: string;
  refreshToken: string;
  isLoggedIn: boolean;
  registrationEmail: string;
  loginEmail: string;
  isNewUser: boolean;
  isRegistrationSuccess: boolean;
  forgotPasswordEmail: string | null;
  verifiedEmail: string | null;
  isEmailVerified: boolean;
  resetPasswordToken: string;
}

const userDataValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  userID: '',
  uuid: '',
  createdAt: '',
};

const initialState: IAuthGenerics<IUserData> = {
  accessToken: '',
  refreshToken: '',
  userData: userDataValues,
  isLoggedIn: false,
  registrationEmail: '',
  loginEmail: '',
  isNewUser: false,
  isRegistrationSuccess: false,
  forgotPasswordEmail: null,
  verifiedEmail: null,
  isEmailVerified: false,
  resetPasswordToken: '',
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, actions) {
      state.userData = actions.payload;
      state.isLoggedIn = true;
    },
    updateUserDetails(state, actions) {
      state.userData = {...state.userData, ...actions.payload};
    },
    logout(state) {
      state.accessToken = '';
      state.refreshToken = '';
      state.isLoggedIn = false;
      state.userData = userDataValues;
    },
    setAccessToken(state, actions) {
      state.accessToken = actions.payload;
    },
    setRefreshToken(state, actions) {
      state.refreshToken = actions.payload;
    },
    setRegistrationEmail(state, action) {
      state.registrationEmail = action.payload;
    },
    setUserEmailWhileLogin(state, action) {
      state.loginEmail = action.payload;
    },
    setIsRegistrationSuccess(state, action) {
      state.isRegistrationSuccess = action.payload;
    },
    setForgotPasswordEmail(state, action) {
      state.forgotPasswordEmail = action.payload;
    },
    setResetPasswordToken(state, action) {
      state.resetPasswordToken = action.payload;
    },
    setVerifiedEmail(state, action) {
      state.verifiedEmail = action.payload;
    },
    setIsEmailVerified(state, action) {
      state.isEmailVerified = action.payload;
    },
  },
});

const authReducer = auth.reducer;

export const authActions = auth.actions;

export default authReducer;
