import {IIConProps} from '@common/interfaces';

export function ErrorIcon(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      id='Group_2580'
      data-name='Group 2580'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 54 54'>
      <circle
        id='Ellipse_35'
        data-name='Ellipse 35'
        cx='27'
        cy='27'
        r='27'
        fill='#fff'
      />
      <path
        id='solid_times'
        data-name='solid times'
        d='M16.521,91.98l6.811-6.811a2.141,2.141,0,0,0,0-3.028l-1.514-1.514a2.141,2.141,0,0,0-3.028,0L11.98,87.438,5.168,80.627a2.141,2.141,0,0,0-3.028,0L.627,82.141a2.141,2.141,0,0,0,0,3.028L7.438,91.98.627,98.791a2.141,2.141,0,0,0,0,3.028l1.514,1.514a2.141,2.141,0,0,0,3.028,0l6.811-6.811,6.811,6.811a2.141,2.141,0,0,0,3.028,0l1.514-1.514a2.141,2.141,0,0,0,0-3.028Z'
        transform='translate(15.02 -64.98)'
        fill={fill}
      />
    </svg>
  );
}

ErrorIcon.defaultProps = {
  fill: '#e0342f',
  width: '45',
  height: '45',
};
