import {IIConProps} from '@common/interfaces';

export function Telephone(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 18'>
      <g id='noun-phone-5318463' transform='translate(-30.603 185.446)'>
        <path
          id='Path_1360'
          data-name='Path 1360'
          d='M103.458,29.744a6.145,6.145,0,0,0-4.3-3.391,1,1,0,0,0-.875.2l-1.691,1.382a1,1,0,0,1-1.16.081,13.137,13.137,0,0,1-4.34-4.34,1,1,0,0,1,.081-1.16l1.382-1.691a1,1,0,0,0,.2-.875,6.145,6.145,0,0,0-3.391-4.3.987.987,0,0,0-.889.02,19.478,19.478,0,0,0-2.512,1.6,1.015,1.015,0,0,0-.395.981,18.769,18.769,0,0,0,5.374,9.92,18.768,18.768,0,0,0,9.92,5.374,1.016,1.016,0,0,0,.981-.395,19.471,19.471,0,0,0,1.6-2.512.988.988,0,0,0,.02-.889Z'
          transform='translate(-54.951 -201)'
          fill={fill}
        />
      </g>
    </svg>
  );
}

Telephone.defaultProps = {
  color: 'white',
  width: '16',
  height: '16',
};
