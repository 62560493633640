import {ReactComponent as Logo} from '@assets/EximImages/PoweredByGSTHeroDark.svg';
import EximLink from '@shared/components/EximLink';

import './index.scss';

interface Props {
  showGstLogo?: boolean;
}
function Footer({showGstLogo}: Props) {
  return (
    <footer className='main-footer' data-testid='footer'>
      <div className='footer-container'>
        <div className='footer-content' data-testid='footer-content'>
          <span>{`Copyright © `}</span>
          <span>
            <strong>{new Date().getFullYear()}</strong>
          </span>
          <span>
            <EximLink
              href='http://perennialsys.com/'
              variantColor='tertiary'
              animationUnderline>
              {` Perennial Systems.`}
            </EximLink>
          </span>
          <span>{` All rights reserved. `}</span>
          <span className='privacy-feedback'>Privacy | Feedback</span>
        </div>
        {showGstLogo && (
          <div className='footer-logo'>
            <Logo />
          </div>
        )}
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  showGstLogo: false,
};

export default Footer;
