import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  themeMode: 'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeState(state, action) {
      state.themeMode = action.payload;
    },
  },
});

const themeReducer = themeSlice.reducer;

export const themeActions = themeSlice.actions;

export default themeReducer;
