import {IIConProps} from '../../common/interfaces';

export function EditProfileIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 26 26'>
      <g
        id='Group_4735'
        data-name='Group 4735'
        transform='translate(-226 -225)'>
        <circle
          id='Ellipse_121'
          data-name='Ellipse 121'
          cx='13'
          cy='13'
          r='13'
          transform='translate(226 225)'
          fill='#f15929'
        />
        <path
          id='solid_pen'
          data-name='solid pen'
          d='M7.656,2.466l3.376,3.376L3.7,13.172.692,13.5a.633.633,0,0,1-.7-.7L.328,9.793Zm5.463-.5L11.534.378a1.266,1.266,0,0,0-1.791,0L8.252,1.869l3.376,3.376,1.491-1.491a1.266,1.266,0,0,0,0-1.791Z'
          transform='translate(232.011 230.993)'
          fill='#fff'
        />
      </g>
    </svg>
  );
}
EditProfileIcon.defaultProps = {
  fill: '#f15929',
  width: '16',
  height: '16',
};
