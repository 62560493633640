import {createSlice} from '@reduxjs/toolkit';

interface IEximProducts {
  productName: string;
  activeGstins: number;
  inactiveGstins: number;
  totalGstins: number;
}

interface IDashboardState {
  eximProducts: IEximProducts[];
  dashboardActiveProduct: string;
  isSubscribeNowClicked: boolean;
  subscribeNowGstin: string;
  selectedBusinessName: string;
  selectedPanNumber: string;
  selectedIECNumber: string;
}

const initialState: IDashboardState = {
  eximProducts: [], // Using mock data because we don't have the API
  dashboardActiveProduct: 'Data Extractor',
  isSubscribeNowClicked: false,
  subscribeNowGstin: '',
  selectedBusinessName: '',
  selectedPanNumber: '',
  selectedIECNumber: '',
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setEximProduct(state, action) {
      state.eximProducts = action.payload;
    },
    setDashboardActiveProduct(state, action) {
      state.dashboardActiveProduct = action.payload;
    },
    setSelectedBusinessName(state, action) {
      state.selectedBusinessName = action.payload;
    },
    setSelectedPanNumber(state, action) {
      state.selectedPanNumber = action.payload;
    },
    setSelectedIECNumber(state, action) {
      state.selectedIECNumber = action.payload;
    },
  },
});

const dashboardReducer = dashboard.reducer;

export const dashboardActions = dashboard.actions;
export default dashboardReducer;
