import {IIConProps} from '@common/interfaces';

export function ViewIcon(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      id='Group_7113'
      data-name='Group 7113'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g id='Group_890' data-name='Group 890'>
        <rect
          id='Rectangle_2126'
          data-name='Rectangle 2126'
          width='24'
          height='24'
          rx='5'
          fill='#c2e2e3'
        />
      </g>
      <path
        id='solid_eye'
        data-name='solid eye'
        d='M15.9,68.928A8.909,8.909,0,0,0,8,64,8.911,8.911,0,0,0,.1,68.928a.9.9,0,0,0,0,.811A8.909,8.909,0,0,0,8,74.667a8.911,8.911,0,0,0,7.9-4.928A.9.9,0,0,0,15.9,68.928ZM8,73.333a4,4,0,1,1,4-4A4,4,0,0,1,8,73.333Zm0-6.667a2.646,2.646,0,0,0-.7.105A1.329,1.329,0,0,1,5.439,68.63,2.661,2.661,0,1,0,8,66.667Z'
        transform='translate(3.999 -57)'
        fill={fill}
      />
    </svg>
  );
}

ViewIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#002662',
};
