import {IIConProps} from '@common/interfaces';

export function ProcessCircle({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14.972 15.425'>
      <g
        id='Group_7100'
        data-name='Group 7100'
        transform='translate(969.15 -2660.891)'>
        <path
          id='Path_1001'
          data-name='Path 1001'
          d='M-966.6,2763.334l.578-.158c.263-.071.432,0,.5.255a1.486,1.486,0,0,1,.046.55c-.065.531-.158,1.059-.24,1.587-.046.3-.089.594-.144.889a1.091,1.091,0,0,1-.109.321.407.407,0,0,1-.578.164,1.157,1.157,0,0,1-.265-.189c-.657-.635-1.313-1.269-1.961-1.912a2.594,2.594,0,0,1-.321-.437.488.488,0,0,1-.057-.2c-.016-.114.041-.183.159-.213.148-.039.295-.083.453-.128-.029-.152-.057-.3-.084-.444a6.513,6.513,0,0,1,.393-3.69,8.316,8.316,0,0,1,1.536-2.415,1.154,1.154,0,0,1,.641-.422.892.892,0,0,1,1.116.96,1.575,1.575,0,0,1-.39.87,6,6,0,0,0-1.2,2.184,4.822,4.822,0,0,0-.113,2.24C-966.629,2763.2-966.613,2763.258-966.6,2763.334Z'
          transform='translate(0 -93.513)'
          fill={fill}
        />
        <path
          id='Path_1002'
          data-name='Path 1002'
          d='M-754.474,3072.885c-.123-.124-.224-.228-.327-.329a.233.233,0,0,1-.025-.37,1.4,1.4,0,0,1,.588-.4c.608-.244,1.218-.482,1.827-.722.223-.088.444-.182.671-.259a1.2,1.2,0,0,1,.375-.071.38.38,0,0,1,.4.4,1.362,1.362,0,0,1-.046.38q-.362,1.3-.738,2.594a1.279,1.279,0,0,1-.321.593.314.314,0,0,1-.5,0c-.146-.138-.292-.278-.449-.427-.041.032-.081.061-.119.094a7.3,7.3,0,0,1-3.071,1.6,7.8,7.8,0,0,1-2.539.2c-.417-.034-.833-.1-1.247-.164a.967.967,0,0,1-.811-.818.927.927,0,0,1,.515-.929,1.681,1.681,0,0,1,1.075-.146,5.922,5.922,0,0,0,4.07-.752C-754.919,3073.218-754.7,3073.048-754.474,3072.885Z'
          transform='translate(-203.538 -399.881)'
          fill={fill}
        />
        <path
          id='Path_1003'
          data-name='Path 1003'
          d='M-688.25,2663.929l-.136.386c-.067.19-.167.255-.354.171a2.059,2.059,0,0,1-.555-.338c-.67-.62-1.326-1.255-1.98-1.892a1.053,1.053,0,0,1-.219-.341.341.341,0,0,1,.139-.439,1.035,1.035,0,0,1,.364-.148c.544-.095,1.091-.175,1.637-.26.381-.059.761-.13,1.144-.168a1.828,1.828,0,0,1,.6.041.317.317,0,0,1,.24.464c-.054.167-.112.333-.174.5-.033.085-.015.126.071.165a7.117,7.117,0,0,1,2.261,1.6,8.046,8.046,0,0,1,1.788,3,9.562,9.562,0,0,1,.273.95.921.921,0,0,1-1.03,1.157,1.209,1.209,0,0,1-.885-.752c-.129-.288-.206-.6-.319-.894a5.692,5.692,0,0,0-2.605-3.084c-.064-.035-.129-.067-.195-.1A.362.362,0,0,0-688.25,2663.929Z'
          transform='translate(-271.049 0)'
          fill={fill}
        />
      </g>
    </svg>
  );
}

ProcessCircle.defaultProps = {
  fill: '#fff',
  width: '15',
  height: '15',
};
