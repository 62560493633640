import DATA_EXTRACTOR from '@assets/EximImages/DataExtractor.svg';
import DUTY_DRAWBACK from '@assets/EximImages/DutyDrawback.svg';
import MOOWR from '@assets/EximImages/Moowr.svg';

const imgs = {
  'Data Extractor': DATA_EXTRACTOR,
  'Duty Drawback': DUTY_DRAWBACK,
  MOOWR,
};

export function getImage(key: string) {
  return imgs[key as keyof typeof imgs];
}

export const SHOW_ENTRIES = ['5', '10', '25', '50', 'all'];

export const disabledProducts = ['Advance License', 'IGCRD'];

export const DASHBOARD_DATA_EXTRACTOR_TABLE_HEADER = [
  {title: 'Business Name', width: '25%'},
  {title: 'PAN No.', width: '25%'},
  {title: 'Subscription Status', width: '40%'},
  {title: 'Action', width: '10%'},
];

export const DASHBOARD_DUTY_DRAWBACK_TABLE_HEADER = [
  {title: 'Business Name', width: '20%'},
  {title: 'PAN No.', width: '14%'},
  {title: 'IEC Number', width: '14%'},
  {title: 'Branch / Unit', width: '14%'},
  {title: 'Subscription Status', width: '28%'},
  {title: 'Action', width: '10%'},
];
