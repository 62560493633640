import {
  AlertStatus,
  EximProducts,
  Path,
  ResponseStatus,
} from '@common/constants';
import {alertActions} from '@core/api/store/alertReducer';
import {logoutApi} from '@pages/Auth/api';
import {authActions} from '@pages/Auth/store/reducer';
import {getImage} from '@pages/Dashboard/utils';
import EximLink from '@shared/components/EximLink';
import {EditProfileIcon, Setting, UserProfile} from '@shared/icons';
import {RootState, dispatch} from '@store';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {IDropDownValues} from './config';

interface IDropdownListProps {
  item: IDropDownValues;
}

interface IUserHeadersProp {
  userName: string;
}

export function SettingsDropdownList(props: IDropdownListProps) {
  const {item} = props;
  return (
    <li
      className={`header-dropdown-list ${
        item.path ? 'header-link-element' : ''
      }`}
      key={item.id}>
      <EximLink
        href={item.path as string}
        className='header-link-element header-link-element'>
        <div className='settings-link-container header-link-element'>
          <span>{item.icon}</span>
          <span>{item.value}</span>
        </div>
      </EximLink>
    </li>
  );
}

interface ISettingsDropDownProps {
  title: string;
  listTitle: string;
  hasDivider?: boolean;
  dropDownValues: IDropDownValues[];
}

export function SettingsDropdown({
  title,
  listTitle,
  hasDivider,
  dropDownValues,
}: ISettingsDropDownProps) {
  return (
    <div className='settings-menu-container header-link-element'>
      <li className='setting settings-menu-title header-link-element'>
        {title}
      </li>
      <li className='setting-li-ul'>
        <ul
          className={`settings-header-dropdown ${
            hasDivider ? 'border-left' : ''
          }`}>
          {dropDownValues?.map((item) =>
            listTitle === item.listTitle ? (
              <SettingsDropdownList item={item} key={item.id} />
            ) : null
          )}
        </ul>
      </li>
    </div>
  );
}

SettingsDropdown.defaultProps = {
  hasDivider: false,
};

export function UserHeaderDropdown({userName}: IUserHeadersProp) {
  const navigate = useNavigate();
  const {
    dashboard: {eximProducts},
    auth: {
      accessToken,
      userData: {createdAt, userID},
    },
  } = useSelector((state: RootState) => state);

  const userCreatedYear = createdAt?.split('.').join(' ');

  const handleLogout = async () => {
    const response = await logoutApi({accessToken, userID});
    if (response.status.toString() === ResponseStatus.SUCCESS) {
      dispatch(authActions.logout());
      navigate(Path.LOGIN);
      dispatch(
        alertActions.setAlertMsg({
          code: 200,
          message: response?.data.message || response?.data['2003'],
          alertType: AlertStatus.SUCCESS,
        })
      );
    }
  };

  return (
    <div className='user-info-container header-link-element'>
      <div className='user-info header-link-element'>
        <div className='profile-container header-link-element'>
          <div className='user-profile'>
            <UserProfile />
            <span>
              <EditProfileIcon />
            </span>
          </div>
          <div className='user-details header-link-element'>
            <span className='user-name'>{userName}</span>
            <span className='user-created'>{`Member Since ${userCreatedYear}`}</span>
            <div className='user-actions'>
              <EximLink href={`${Path.PROFILE}${Path.USER_PROFILE}`}>
                <span className='my-profile header-link-element'>
                  My Profile
                </span>
              </EximLink>
              <span>|</span>
              <span
                role='presentation'
                onClick={handleLogout}
                className='logout header-link-element'>
                Logout
              </span>
            </div>
          </div>
        </div>
        <div className='subscribe-details'>
          <span>Subscribed Products</span>
          <span>
            <Setting fill='#B5BAD3' height={16} width={16} />
          </span>
        </div>
      </div>
      <div className='subscribe-products-container header-link-element'>
        {/* TODO: Remove slice when we have all product active */}
        {eximProducts
          .slice(0, 2)
          .map(({productName, activeGstins, inactiveGstins}, index) => (
            <div
              className='subscribe-products header-link-element'
              key={`${productName}${index + 1}`}>
              <div className='product-icon header-link-element'>
                <img src={getImage(productName)} alt='logo' />
              </div>
              <div className='product-details header-link-element'>
                <span className='product-name'>{productName}</span>
                <span className='gstin-count'>{`${
                  productName === EximProducts.DATA_EXTRACTOR ? 'PAN' : 'IEC'
                } count : ${activeGstins}/${
                  activeGstins + inactiveGstins
                }`}</span>
                {/* TODO: Below date will be change based on the plan expiry date, for now we don't have  */}
                {/* <span className='expiry-date'>Expiry on : 25 April, 2023</span> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
