import {IIConProps} from '@common/interfaces';

export function ClaimReportIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 27.675'>
      <g id='noun-reports-6131726' transform='translate(-11.1 -17.9)'>
        <path
          id='Path_5793'
          data-name='Path 5793'
          d='M34.411,24.158l-5.569-5.569a2.3,2.3,0,0,0-1.665-.689H13.454A2.328,2.328,0,0,0,11.1,20.254V43.221a2.328,2.328,0,0,0,2.354,2.354H32.746A2.328,2.328,0,0,0,35.1,43.221v-17.4A2.209,2.209,0,0,0,34.411,24.158ZM21.205,27.374l-3.1,3.1c-.8.8-2.01-.459-1.206-1.206l3.732-3.732a.833.833,0,0,1,1.206,0l3.1,3.1L28.1,25.479c.8-.8,2.01.459,1.206,1.206l-3.789,3.789a.833.833,0,0,1-1.206,0Zm2.067,7.349H16.44a.862.862,0,0,1,0-1.722h6.833A.862.862,0,0,1,23.272,34.723Zm-6.89,4.651h9.187a.862.862,0,0,1,0,1.722H16.382A.862.862,0,0,1,16.382,39.374Zm13.435-1.493H16.44a.862.862,0,0,1,0-1.722H29.818A.862.862,0,0,1,29.818,37.881Z'
          fill={fill}
        />
      </g>
    </svg>
  );
}

ClaimReportIcon.defaultProps = {
  fill: '#5478b5',
  width: '16',
  height: '18',
};
