import Link, {Props as ILinkInterface} from '@submodules/Link/Link';

import './index.scss';

interface Prop extends ILinkInterface {
  animationUnderline?: boolean;
  variantColor?:
    | 'information'
    | 'tertiary'
    | 'shadow'
    | 'text'
    | 'white'
    | 'primary';
}

export default function EximLink({
  href,
  children,
  underline,
  animationUnderline,
  variantColor,
  target,
  onClick,
}: Prop) {
  return (
    <span
      className={`link-wrapper ${variantColor} ${
        animationUnderline && 'animation-underline'
      }`}>
      <Link href={href} target={target} underline={underline} onClick={onClick}>
        {children}
      </Link>
    </span>
  );
}

EximLink.defaultProps = {
  animationUnderline: false,
  variantColor: 'information',
};
