import {IIConProps} from '../utils/interface';

export function UserManagement({fill, width, height}: IIConProps) {
  return (
    <svg
      id='manage_accounts_black_24dp'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 21 21'>
      <g id='Group_5327' data-name='Group 5327'>
        <path
          id='Path_912'
          data-name='Path 912'
          d='M0,0H21V21H0Z'
          fill='none'
        />
      </g>
      <g id='Group_5329' data-name='Group 5329' transform='translate(1.75 3.5)'>
        <g id='Group_5328' data-name='Group 5328'>
          <circle
            id='Ellipse_140'
            data-name='Ellipse 140'
            cx='3.5'
            cy='3.5'
            r='3.5'
            transform='translate(3.25 0.5)'
            fill={fill}
          />
          <path
            id='Path_913'
            data-name='Path 913'
            d='M9.586,13.017C9.394,13.009,9.2,13,9,13a11.266,11.266,0,0,0-5.784,1.592A2.557,2.557,0,0,0,2,16.806v2.319h8.1a6.093,6.093,0,0,1-1.1-3.5A6.188,6.188,0,0,1,9.586,13.017Z'
            transform='translate(-2 -5.125)'
            fill={fill}
          />
          <path
            id='Path_914'
            data-name='Path 914'
            d='M19.677,15.375a3.8,3.8,0,0,0-.052-.551l1-.884-.875-1.514-1.269.429a3.191,3.191,0,0,0-.945-.551L17.271,11h-1.75l-.262,1.3a3.191,3.191,0,0,0-.945.551l-1.269-.429L12.17,13.94l1,.884a3.8,3.8,0,0,0-.053.551,3.8,3.8,0,0,0,.053.551l-1,.884.875,1.514,1.269-.429a3.191,3.191,0,0,0,.945.551l.262,1.3h1.75l.262-1.3a3.191,3.191,0,0,0,.945-.551l1.269.429.875-1.514-1-.884A3.8,3.8,0,0,0,19.677,15.375ZM16.4,17.125a1.75,1.75,0,1,1,1.75-1.75A1.755,1.755,0,0,1,16.4,17.125Z'
            transform='translate(-3.271 -4.875)'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

UserManagement.defaultProps = {
  fill: '#5478b5',
  width: '21',
  height: '21',
};
