import {createSlice} from '@reduxjs/toolkit';

export interface IDbkClaimDetails {
  claimTitle: string;
  startPeriod: string;
  endPeriod: string;
  applicantType: string;
  claimTxnId: string;
  sbSelectTxnId: string;
  isClaimExist: boolean;
  claimCalculationStatus: string;
}

interface IInitialState {
  panNumber: string;
  iecNumber: string;
  businessName: string;
  invoiceTxnId: string;
  invoiceErrorFileId: string;
  invoicesCardActive: boolean;
  isLastTransactionInvalid: boolean;
  invoicesPeriod: {
    startPeriod: string;
    endPeriod: string;
  };
  dbkClaim: IDbkClaimDetails;
  getClaimHistory: () => void;
  getSbListFunc: () => void;
}

export const initialClaimDetails = {
  claimTitle: '',
  startPeriod: '',
  endPeriod: '',
  applicantType: '',
  claimTxnId: '',
  sbSelectTxnId: '',
  isClaimExist: false,
  claimCalculationStatus: '',
};

const initialState: IInitialState = {
  panNumber: '',
  iecNumber: '',
  businessName: '',
  invoiceTxnId: '',
  invoiceErrorFileId: '',
  invoicesCardActive: true,
  isLastTransactionInvalid: false,
  invoicesPeriod: {
    startPeriod: '',
    endPeriod: '',
  },
  dbkClaim: initialClaimDetails,
  // INFO: set the function because need to call that function after discard the claim to update the table data
  getClaimHistory: () => {
    /* */
  },
  // INFO: set the function because need to call that function after edit the claim to update the sb list table data
  getSbListFunc: () => {
    /* */
  },
};

const dutyDrawback = createSlice({
  name: 'dutyDrawback',
  initialState,
  reducers: {
    setPanNumber(state, actions) {
      state.panNumber = actions.payload;
    },
    setIecNumber(state, actions) {
      state.iecNumber = actions.payload;
    },
    setBusinessName(state, actions) {
      state.businessName = actions.payload;
    },
    setInvoiceTxnId(state, actions) {
      state.invoiceTxnId = actions.payload;
    },
    setInvoiceErrorFileId(state, actions) {
      state.invoiceErrorFileId = actions.payload;
    },
    setInvoicesCardActive(state, actions) {
      state.invoicesCardActive = actions.payload;
    },
    setInvoicesPeriod(state, actions) {
      state.invoicesPeriod = actions.payload;
    },
    setIsLastTransactionInvalid(state, actions) {
      state.isLastTransactionInvalid = actions.payload;
    },
    setDbkClaim(state, action) {
      state.dbkClaim.claimTitle = action.payload.claimTitle;
      state.dbkClaim.startPeriod = action.payload.startPeriod;
      state.dbkClaim.endPeriod = action.payload.endPeriod;
      state.dbkClaim.applicantType = action.payload.applicantType;
      state.dbkClaim.claimTxnId = action.payload.claimTxnId;
      state.dbkClaim.sbSelectTxnId = action.payload.sbSelectTxnId;
      state.dbkClaim.isClaimExist = action.payload.isClaimExist;
    },
    setClaimCalculationStatus(state, action) {
      state.dbkClaim.claimCalculationStatus = action.payload;
    },
    setGetClaimHistoryFunc(state, action) {
      state.getClaimHistory = action.payload;
    },
    setGetSbListFunc(state, action) {
      state.getSbListFunc = action.payload;
    },
  },
});

const dutyDrawbackReducer = dutyDrawback.reducer;

export const dutyDrawbackActions = dutyDrawback.actions;
export default dutyDrawbackReducer;
