import {IIConProps} from '@common/interfaces';

export function DeleteSolid(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_7396'
        data-name='Group 7396'
        transform='translate(-872 -1650)'>
        <g id='Group_893' data-name='Group 893'>
          <g id='Group_864' data-name='Group 864' transform='translate(-100)'>
            <g id='Group_855' data-name='Group 855'>
              <rect
                id='Rectangle_2087'
                data-name='Rectangle 2087'
                width='24'
                height='24'
                rx='5'
                transform='translate(972 1650)'
                fill='#c2e2e3'
              />
              <path
                id='solid_trash'
                data-name='solid trash'
                d='M11.571.857H8.356L8.1.356A.643.643,0,0,0,7.529,0H4.467a.635.635,0,0,0-.573.357l-.251.5H.429A.429.429,0,0,0,0,1.286v.857a.429.429,0,0,0,.429.429H11.571A.429.429,0,0,0,12,2.143V1.286A.429.429,0,0,0,11.571.857ZM1.425,12.507a1.286,1.286,0,0,0,1.283,1.207H9.291a1.286,1.286,0,0,0,1.286-1.205l.567-9.081H.857Z'
                transform='translate(978 1655)'
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

DeleteSolid.defaultProps = {
  width: '24',
  height: '24',
  fill: '#02346a',
};
