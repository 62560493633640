import {IIConProps} from '../../common/interfaces';

export function Plus({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14 14'>
      <g id='noun-plus-2224975' transform='translate(-70.002)'>
        <path
          id='Path_1371'
          data-name='Path 1371'
          d='M76.026,0h1.953a.789.789,0,0,1,.8.776V5.247h4.447A.783.783,0,0,1,84,6.024V7.976a.789.789,0,0,1-.776.8H78.778v4.447a.789.789,0,0,1-.8.776H76.026a.783.783,0,0,1-.776-.776V8.776H70.778a.789.789,0,0,1-.776-.8V6.024a.783.783,0,0,1,.776-.776h4.471V.776A.783.783,0,0,1,76.026,0Z'
          fill={fill}
          fillRule='evenodd'
        />
      </g>
    </svg>
  );
}

Plus.defaultProps = {
  fill: '#02346a',
  width: '16',
  height: '16',
};
