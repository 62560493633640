import {Api, ApiAction, ApiVersion} from '@common/constants/index';
import {get, post} from '@core/api/axios';
import {AxiosResponse} from 'axios';

export interface IRegisterApiProps {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  password: string;
  confirmPassword?: string;
  weeklyUpdates: boolean;
  inviteId?: string;
  type: string;
  status?: string;
}

export interface ILoginApiProps {
  email: string;
  password: string;
}

export interface ILogoutProps {
  accessToken: string;
  userID: string;
}

export interface IForgotApiProps {
  email: string;
}

export interface IResetPassword {
  password: string;
  confirmPassword?: string;
}

export interface IChangePasswordApiProps {
  email: string;
  usrPwd: string;
  usrSessionToken: string;
}

const {
  EXIM_SUBSCRIPTION_SERVICE,
  USERS,
  SIGNUP,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESEND_VERIFY_EMAIL_LINK,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  VERIFY_REQUEST,
  MANAGEMENT,
  INVITED,
  VERIFY,
} = Api;

const {V1} = ApiVersion;

// INFO: Below URL is common for all the API in entire file
const COMMON_BASE_URL = `${EXIM_SUBSCRIPTION_SERVICE}${V1}${USERS}`;

export const registrationApi = async (data: IRegisterApiProps) => {
  const response = await post(`${COMMON_BASE_URL}${SIGNUP}`, data);
  return response as AxiosResponse;
};

export const loginApi = async (data: ILoginApiProps) => {
  const response = await post(`${COMMON_BASE_URL}${LOGIN}`, data);
  return response as AxiosResponse;
};

export const logoutApi = async (data: ILogoutProps) => {
  const response = await post(`${COMMON_BASE_URL}${LOGOUT}`, {
    token: data.accessToken,
    userId: data.userID,
  });
  return response as AxiosResponse;
};

export const forgotPasswordApi = async (email: string) => {
  const response = await get(`${COMMON_BASE_URL}${FORGOT_PASSWORD}/${email}`);
  return response as AxiosResponse;
};

export const resendVerifyEmailLink = async (email: string) => {
  const response = await get(
    `${COMMON_BASE_URL}${RESEND_VERIFY_EMAIL_LINK}/${email}`
  );
  return response as AxiosResponse;
};

export const verifyEmailApi = async (token: string) => {
  const response = await get(`${COMMON_BASE_URL}${VERIFY_EMAIL}/${token}`);
  return response as AxiosResponse;
};

export const verifyRequestApi = async (token: string) => {
  const response = await get(
    `${COMMON_BASE_URL}${VERIFY_REQUEST}?token=${token}&requestType=RESET_PASSWORD`
  );
  return response as AxiosResponse;
};

export const resetPasswordApi = async (password: string, token: string) => {
  const response = await post(
    `${COMMON_BASE_URL}${RESET_PASSWORD}?password=${password}&token=${token}`
  );
  return response as AxiosResponse;
};

// INFO: Below API to related to the user management and need to call on registration page after sending the invitation
export const getInvitedUser = async (token: string) => {
  const response = await get(
    `${COMMON_BASE_URL}${MANAGEMENT}${INVITED}${USERS}${VERIFY}/${token}`,
    {
      headers: {
        action: ApiAction.MANAGE_USER,
      },
    }
  );
  return response as AxiosResponse;
};
