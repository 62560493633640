import {createSlice} from '@reduxjs/toolkit';

interface IInitialState {
  shouldCompanyProfileShow: boolean;
  profilePan: string;
  selectedProductName: string;
}

export const initialState: IInitialState = {
  shouldCompanyProfileShow: false,
  profilePan: '',
  selectedProductName: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setShouldCompanyProfileShow(state, actions) {
      state.shouldCompanyProfileShow = actions.payload;
    },
    setProfilePan(state, actions) {
      state.profilePan = actions.payload;
    },
    setSelectedProductName(state, actions) {
      state.selectedProductName = actions.payload;
    },
  },
});

const profileReducer = profileSlice.reducer;

export const profileActions = profileSlice.actions;
export default profileReducer;
