import store, {persistor} from '@store';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';

// TODO: commenting the theme configuration because no needed for now
// import {DefaultTheme, ThemeProvider} from 'styled-components';
import App from './App';
import './assets/i18n';
// import Config from './config/tenantConfig.json';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const {theme} = Config[0];

function RootApp() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          {/* <ThemeProvider theme={theme as DefaultTheme}>
          </ThemeProvider> */}
          <App />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
}

root.render(<RootApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
