export function EximHeroLoader() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100'
      height='100'
      viewBox='0 0 100 100'>
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.019'
          y1='0.662'
          x2='0.77'
          y2='0.167'
          gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#f05a28' />
          <stop offset='1' stopColor='#be1e2d' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-2'
          x1='-0.032'
          y1='0.5'
          x2='1'
          y2='0.5'
          gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#be1e2d' />
          <stop offset='0.079' stopColor='#c92b2b' />
          <stop offset='0.259' stopColor='#de4529' />
          <stop offset='0.422' stopColor='#eb5428' />
          <stop offset='0.552' stopColor='#f05a28' />
          <stop offset='0.709' stopColor='#ed5728' />
          <stop offset='0.808' stopColor='#e64e28' />
          <stop offset='0.89' stopColor='#da402a' />
          <stop offset='0.963' stopColor='#c92b2b' />
          <stop offset='1' stopColor='#be1e2d' />
        </linearGradient>
      </defs>
      <g
        id='Group_13596'
        data-name='Group 13596'
        transform='translate(9854 18264.824)'>
        <circle
          id='Ellipse_32'
          data-name='Ellipse 32'
          cx='50'
          cy='50'
          r='50'
          transform='translate(-9854 -18264.824)'
          fill='#002662'
        />
        <g
          id='Group_553'
          data-name='Group 553'
          transform='translate(-9854.163 -18264.824)'>
          <path
            id='Path_155'
            data-name='Path 155'
            d='M57.488,43.414a1.365,1.365,0,0,0-.195-.787c-.021-.038-.044-.075-.066-.11-.152-.236-.559-.6-.734-.815l-6.318-6.29L50,35.25l-7.529,7.5a1.3,1.3,0,0,0-.333.8V58.324c0,.259.149.321.333.139L57.4,43.6A.332.332,0,0,0,57.488,43.414Z'
            fill='url(#linear-gradient)'
          />
          <g id='Group_552' data-name='Group 552'>
            <path
              id='Path_156'
              data-name='Path 156'
              d='M57.856,42.761v-.14c0-.11,0-.242,0-.424V27.814a1.3,1.3,0,0,0-.333-.8L42.475,12.03c-.184-.182-.333-.12-.333.139V26.944a1.3,1.3,0,0,0,.333.8L56.493,41.7c.175.215.582.579.734.815.022.035.045.072.066.11a1.365,1.365,0,0,1,.195.787.344.344,0,0,1-.09.191,1.334,1.334,0,0,0,.4-.583,1.164,1.164,0,0,0,.043-.177A.193.193,0,0,0,57.856,42.761Z'
              fill='url(#linear-gradient-2)'
            />
          </g>
        </g>
        <g
          id='Group_13595'
          data-name='Group 13595'
          transform='translate(-9840 -18206.256)'>
          <text
            id='EximHero'
            transform='translate(0 16)'
            fill='#fff'
            fontSize='15'
            fontFamily='Segoe UI Variable Small'
            fontWeight='700'>
            <tspan x='0' y='0'>
              Exim
            </tspan>
            <tspan y='0' fontWeight='400'>
              Hero
            </tspan>
          </text>
          <text
            id='TM'
            transform='translate(69 7.347)'
            fill='#fff'
            fontSize='4'
            fontFamily='SourceSansPro-Regular, Source Sans Pro'>
            <tspan x='0' y='0'>
              TM
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}
