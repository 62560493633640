import {EximHeroLoader} from '@shared/icons/EximHeroLoader';

import './index.scss';

export default function EximLoader() {
  return (
    <div className='wrapper'>
      <div className='loader-wrapper' data-testid='loader-wrapper'>
        <div className='loader-circle' />
        <div className='loader-exim-img'>
          <EximHeroLoader />
        </div>
      </div>
    </div>
  );
}
