import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  panNumber: '',
  businessName: '',
  advanceExportPeriod: {
    startPeriod: '',
    endPeriod: '',
  },
  isApplyClickedOnAdvanceExport: false,
};

const dataExtractor = createSlice({
  name: 'dataExtractor',
  initialState,
  reducers: {
    setPanNumber(state, actions) {
      state.panNumber = actions.payload;
    },
    setBusinessName(state, actions) {
      state.businessName = actions.payload;
    },
    setAdvanceExportPeriod(state, actions) {
      state.advanceExportPeriod = actions.payload;
    },
    setIsApplyClickedOnAdvanceExport(state, actions) {
      state.isApplyClickedOnAdvanceExport = actions.payload;
    },
  },
});

const dataExtractorReducer = dataExtractor.reducer;

export const dataExtractorActions = dataExtractor.actions;
export default dataExtractorReducer;
