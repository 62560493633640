import {IIConProps} from '../utils/interface';

export function NotificationSettings({fill, width, height}: IIConProps) {
  return (
    <svg
      id='circle_notifications_black_24dp'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 21 21'>
      <path id='Path_915' data-name='Path 915' d='M0,0H21V21H0Z' fill='none' />
      <path
        id='Path_916'
        data-name='Path 916'
        d='M10.5,2A8.5,8.5,0,1,0,19,10.5,8.5,8.5,0,0,0,10.5,2Zm0,14.025A1.273,1.273,0,0,1,9.225,14.75h2.55A1.273,1.273,0,0,1,10.5,16.025ZM14.75,13.9H6.25v-.85l.85-.85V9.982A3.537,3.537,0,0,1,9.65,6.25V5.825a.85.85,0,1,1,1.7,0V6.25A3.543,3.543,0,0,1,13.9,9.981V12.2l.85.85Z'
        fill={fill}
      />
    </svg>
  );
}

NotificationSettings.defaultProps = {
  fill: '#5478b5',
  width: '21',
  height: '21',
};
