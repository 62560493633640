import {IIConProps} from '@common/interfaces';

export function ExclamationTriangle(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 70.38 62.561'>
      <path
        id='solid_exclamation-triangle'
        data-name='solid exclamation-triangle'
        d='M69.588,53.764a5.868,5.868,0,0,1-5.08,8.8H5.871a5.868,5.868,0,0,1-5.08-8.8L30.11,2.931a5.869,5.869,0,0,1,10.16,0L69.588,53.764Zm-34.4-10.51a5.621,5.621,0,1,0,5.621,5.621A5.621,5.621,0,0,0,35.19,43.255Zm-5.336-20.2.906,16.618a1.466,1.466,0,0,0,1.464,1.386h5.932a1.466,1.466,0,0,0,1.464-1.386l.906-16.618a1.466,1.466,0,0,0-1.464-1.546H31.318a1.466,1.466,0,0,0-1.464,1.546Z'
        fill={fill}
      />
    </svg>
  );
}

ExclamationTriangle.defaultProps = {
  width: '70.4',
  height: '62.5',
  fill: '#f8b226',
};
