import {IIConProps} from '../utils/interface';

export function CompanyProfile({fill, width, height}: IIConProps) {
  return (
    <svg
      id='business_black_24dp'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 21 21'>
      <path id='Path_910' data-name='Path 910' d='M0,0H21V21H0Z' fill='none' />
      <path
        id='Path_911'
        data-name='Path 911'
        d='M10.5,6.333V3H2V18H19V6.333Zm-5.1,10H3.7V14.667H5.4ZM5.4,13H3.7V11.333H5.4Zm0-3.333H3.7V8H5.4Zm0-3.333H3.7V4.667H5.4Zm3.4,10H7.1V14.667H8.8ZM8.8,13H7.1V11.333H8.8Zm0-3.333H7.1V8H8.8Zm0-3.333H7.1V4.667H8.8Zm8.5,10H10.5V14.667h1.7V13H10.5V11.333h1.7V9.667H10.5V8h6.8ZM15.6,9.667H13.9v1.667h1.7ZM15.6,13H13.9v1.667h1.7Z'
        fill={fill}
      />
    </svg>
  );
}

CompanyProfile.defaultProps = {
  fill: '#5478b5',
  width: '21',
  height: '21',
};
