import {IIConProps} from '../utils/interface';

export function DataParsingSetupIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24.617 25'>
      <g
        id='Group_15103'
        data-name='Group 15103'
        transform='translate(-1164.752 -272.913)'>
        <g
          id='Group_15102'
          data-name='Group 15102'
          transform='translate(22622.498 -4318.368)'>
          <path
            id='Path_3445'
            data-name='Path 3445'
            d='M46.6,41.66H30.9c-2.454,0-4.459-1.659-4.459-3.705V20.365c0-2.039,2-3.705,4.459-3.705H46.6c2.454,0,4.459,1.659,4.459,3.705v17.59C51.057,39.994,49.06,41.66,46.6,41.66ZM30.9,17.632a3.056,3.056,0,0,0-3.289,2.733v17.59A3.056,3.056,0,0,0,30.9,40.688H46.6a3.056,3.056,0,0,0,3.289-2.733V20.365A3.056,3.056,0,0,0,46.6,17.632Z'
            transform='translate(-21484.186 4574.621)'
            fill={fill}
          />
          <path
            id='Path_3446'
            data-name='Path 3446'
            d='M47.025,36.918a.9.9,0,0,1-.9.9H32.637a.9.9,0,0,1-.9-.9V29.337a.9.9,0,0,1,.9-.9H46.128a.9.9,0,0,1,.9.9Z'
            transform='translate(-21484.826 4571.202)'
            fill={fill}
          />
        </g>
        <rect
          id='Rectangle_16953'
          data-name='Rectangle 16953'
          width='4.96'
          height='2.005'
          transform='translate(1171.207 286.849)'
          fill='#fff'
        />
        <rect
          id='Rectangle_16954'
          data-name='Rectangle 16954'
          width='4.96'
          height='2.005'
          transform='translate(1171.207 283.059)'
          fill='#fff'
        />
        <rect
          id='Rectangle_16955'
          data-name='Rectangle 16955'
          width='4.96'
          height='2.005'
          transform='translate(1177.951 283.059)'
          fill='#fff'
        />
        <rect
          id='Rectangle_16956'
          data-name='Rectangle 16956'
          width='4.96'
          height='2.014'
          transform='translate(1177.951 286.849)'
          fill='#fff'
        />
      </g>
    </svg>
  );
}

DataParsingSetupIcon.defaultProps = {
  fill: '#5478b5',
  width: '18',
  height: '18',
};
