import {IIConProps} from '@common/interfaces';

export function SuccessIcon(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      id='Group_6944'
      data-name='Group 6944'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 39 39'>
      <g id='Group_701' data-name='Group 701' transform='translate(0 0)'>
        <circle
          id='Ellipse_33'
          data-name='Ellipse 33'
          cx='19.5'
          cy='19.5'
          r='19.5'
          fill='#fff'
        />
        <path
          id='solid_check'
          data-name='solid check'
          d='M8.182,81.242.353,74.065a1.04,1.04,0,0,1,0-1.561l1.7-1.561a1.283,1.283,0,0,1,1.7,0l5.274,4.835,11.3-10.355a1.283,1.283,0,0,1,1.7,0l1.7,1.561a1.04,1.04,0,0,1,0,1.561L9.886,81.242a1.283,1.283,0,0,1-1.7,0Z'
          transform='translate(8.205 -53.749)'
          fill={fill}
        />
      </g>
    </svg>
  );
}

SuccessIcon.defaultProps = {
  fill: '#4ca75b',
  width: '39',
  height: '39',
};
