import {PageType} from '@common/constants';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface IStoreHeader {
  headerPageName: PageType;
  businessName: string;
}

const initialState: IStoreHeader = {
  headerPageName: 'DASHBOARD',
  businessName: '',
};

const header = createSlice({
  name: 'Header',
  initialState,
  reducers: {
    setHeaderPageName(state, action: PayloadAction<PageType>) {
      state.headerPageName = action.payload;
    },
    setBusinessName(state, action) {
      state.businessName = action.payload;
    },
  },
});

const headerReducer = header.reducer;

export const headerActions = header.actions;

export default headerReducer;
