import {Path} from '@common/constants';
import EximLoader from '@shared/components/EximLoader';
import {RootState} from '@store';
import {ReactNode, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';

import ROUTES from './routes';

interface IRoutesProps {
  children: ReactNode;
  isAuthenticated: boolean;
}
interface IPublicRoutesProps extends IRoutesProps {
  isNewUser: boolean;
}

function PrivateRoute(props: IRoutesProps) {
  const {children, isAuthenticated} = props;
  return isAuthenticated ? (
    (children as JSX.Element)
  ) : (
    <Navigate to={Path.LOGIN} />
  );
}

function PublicRoute(props: IPublicRoutesProps) {
  const {children, isAuthenticated, isNewUser} = props;
  return isAuthenticated && !isNewUser ? (
    <Navigate to={Path.DASHBOARD} />
  ) : (
    (children as JSX.Element)
  );
}

function AppRoutes() {
  const {isLoggedIn, isNewUser} = useSelector((state: RootState) => state.auth);

  return (
    <Suspense
      fallback={
        <div>
          <EximLoader />
        </div>
      }>
      <Routes>
        {ROUTES.map((route) =>
          route.isPrivate ? (
            <Route
              key={route.name}
              path={route.path}
              element={
                <PrivateRoute isAuthenticated={isLoggedIn}>
                  <route.component />
                </PrivateRoute>
              }
            />
          ) : (
            <Route
              key={route.name}
              path={route.path}
              element={
                route.exact ? (
                  <PublicRoute
                    isAuthenticated={isLoggedIn}
                    isNewUser={isNewUser}>
                    <route.component />
                  </PublicRoute>
                ) : (
                  <route.component />
                )
              }
            />
          )
        )}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
