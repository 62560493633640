interface IRoundCheckProps {
  fill?: string;
  width?: number;
  height?: number;
}

export function SolidSync({fill, width, height}: IRoundCheckProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_12721'
        data-name='Group 12721'
        transform='translate(-689 -1650)'>
        <g id='Group_863' data-name='Group 863' transform='translate(-100)'>
          <rect
            id='Rectangle_13'
            data-name='Rectangle 13'
            width='24'
            height='24'
            rx='5'
            transform='translate(789 1650)'
            fill='#c2e2e3'
          />
          <path
            id='solid_sync-alt'
            data-name='solid sync-alt'
            d='M16.775,11.031A4.065,4.065,0,0,0,10.058,13a.291.291,0,0,1-.282.221H8.39a.29.29,0,0,1-.286-.343A6,6,0,0,1,18.145,9.662l.864-.864A.581.581,0,0,1,20,9.208v3.243a.581.581,0,0,1-.581.581H16.176a.581.581,0,0,1-.411-.991ZM8.581,14.968h3.243a.581.581,0,0,1,.411.991l-1.01,1.01A4.065,4.065,0,0,0,17.942,15a.291.291,0,0,1,.282-.221H19.61a.29.29,0,0,1,.286.343A6,6,0,0,1,9.855,18.338l-.864.864A.581.581,0,0,1,8,18.792V15.548A.581.581,0,0,1,8.581,14.968Z'
            transform='translate(787 1648)'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

SolidSync.defaultProps = {
  fill: '#012d66',
  width: '24',
  height: '24',
};
