import {REGEXP, SubscriptionStatus, UploadLogsStatus} from '@common/constants';
import {
  IBusinessDetails,
  ICommonKeyValue,
  IInputFilesProcessDetails,
  IRegularDropdownData,
  ISubscribedProducts,
} from '@common/interfaces';
import {IApiData as DDApiData} from '@pages/DutyDrawback/api';
import {IApiData as MoowrApiData} from '@pages/Moowr/api';
import {addHours, addMinutes, format, parse, subMonths} from 'date-fns';

// add needed utils here
export const falsyArray = [null, undefined, ''];

export const isFalsy = (key: unknown) =>
  falsyArray.includes(key as null | string | undefined);

// limit mobile number to 10 digits
export const limitPhoneNumber = (value: string) => {
  return Math.max(0, Number(value)).toString().slice(0, 10);
};

// limit text its max length
export const limitText = (value: string, maxLength: number) => {
  return value.toString().slice(0, maxLength);
};

export const getPaymentStatus = (status: string) => {
  switch (status) {
    case SubscriptionStatus.EXPIRED:
      return 'Expired';
    case SubscriptionStatus.PENDING_ACTIVATION:
    case SubscriptionStatus.PENDING_PAYMENT:
      return 'Pending';
    case SubscriptionStatus.PAID:
      return 'Active';
    case SubscriptionStatus.UNREGISTERED:
      return 'Not Subscribed';
    case SubscriptionStatus.FREE:
      return 'Free';
    default:
      return '';
  }
};

export const getStatusLink = (status: string) => {
  switch (status) {
    case SubscriptionStatus.EXPIRED:
      return 'Renew';
    case SubscriptionStatus.UNREGISTERED:
      return 'Subscribe Now';
    case SubscriptionStatus.FREE:
      return 'Upgrade Now';
    default:
      return '';
  }
};

// Below function to get the current tax period date - 042023 / April 2023
const currentTaxPeriodDate = () => {
  const date = new Date();
  let month: string | number = date.getMonth() + 1;
  const year = `${date.getFullYear()}`;
  if (month < 10) month = `0${month}`;
  return `${month}${year}`;
};
export const currentTaxPeriod = currentTaxPeriodDate();

// date format output for below function is 21/02/2023;
export function getCurrentDate() {
  const currentDate = new Date();
  let month: number | string = currentDate.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let dateOfMonth: number | string = currentDate.getDate();
  if (dateOfMonth < 10) dateOfMonth = `0${dateOfMonth}`;
  const year: number | string = currentDate.getFullYear();
  return `${dateOfMonth}/${month}/${year}`;
}

const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// date format output for below function is input-"01-2019" / output- "Jan 23"
export function getClaimPeriod(date: string) {
  const month = +date.slice(0, 2);
  const year = date.slice(5);
  return `${monthList[month - 1]} ${year}`;
}

// date format output for below function is input-01-2023 / output- Jan 2023
export function formatSelectedPeriod(date: string) {
  const [month, year] = date.split('-');
  return `${monthList[+month - 1]} ${year}`;
}

// Below function to get the user initials - Java Script => JS
export const getUserInitials = (fullName: string) => {
  return fullName.match(REGEXP.userInitials)?.join('').toUpperCase();
};

// Below function to get the product and showing on the dashboard in the carousel
export const getProductToShow = (windowWidth: number) => {
  const productWidth = 265;
  const totalProduct = 8;
  const allProductsWidth = 2180;
  const neededWidth = allProductsWidth % windowWidth;
  const extraProduct = Math.ceil(neededWidth / productWidth);
  return totalProduct - extraProduct;
};

// Below function returns the option id based-on the passed value
export const selectedOptionId = (
  options: IRegularDropdownData[],
  value: string
) => {
  const selectedItem = options.find(
    (el: IRegularDropdownData) =>
      el.value?.toLowerCase() === value?.toLowerCase() ||
      el.label?.toLowerCase() === value?.toLowerCase()
  );
  return Number(selectedItem?.id);
};

// format the amount with rupee symbol and coma
export const formatAmount = (val: string | number, type?: '₹' | 'Rs.') => {
  const strValue = `${type ?? ''} ${
    val === 0 ? '0' : val?.toString().replace(REGEXP.formatNumberWithComma, ',')
  }`;
  return strValue.trim();
};

export const capitalizeFirstLetter = (word: string) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '';
};

export const checkFilesExtension = (
  filesArray: File[],
  typeArray: string[]
) => {
  for (let i = 0; i < filesArray.length; i += 1) {
    const file = filesArray[i];
    if (!typeArray.includes(file.type)) {
      return false;
    }
  }
  return true;
};

// Below function to modify the files data and its category as per the API request body
// Note: it is only for support-doc upload API
export const formatFilesData = (
  files: File[],
  categories: {[key: string]: string}
) => {
  const categoriesArray = [];
  const newFileArray = [];

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const {name, lastModified} = file;
    const fileExtension = name.split('.')[1];
    const randomName = Math.floor(Math.random() * 10 + 1);
    const fileKey = `${lastModified}${randomName}`;
    const newFilename = `${fileKey}.${fileExtension}`;
    const modifiedFile = new File([file], newFilename, {type: file.type});

    const categoryDetails = {
      'file-key': fileKey,
      'display-name': name,
      category: categories[lastModified],
    };
    categoriesArray.push(categoryDetails);
    newFileArray.push(modifiedFile);
  }
  return {newFileArray, categoriesArray};
};

// Below function to calculate the size of all the uploading files in bytes
export const getAllFilesSize = (files: File[]) => {
  let totalSize = 0;
  for (let i = 0; i < files.length; i += 1) {
    totalSize += files[i].size;
  }
  return totalSize;
};

// Below function for convert MB to Bytes
export const MbToBytes = (mb: number) => {
  return mb * 1024 * 1024;
};

// Below function to get the key name and value to the upload logs table
export const getObjKeyValue = (obj: {[key: string]: number}) => {
  const keys = Object.keys(obj);
  let key;
  let value;
  if (keys.includes(UploadLogsStatus.IN_PROGRESS)) {
    key = UploadLogsStatus.IN_PROGRESS;
    value = obj[key];
  } else if (keys.includes(UploadLogsStatus.FAILED)) {
    key = UploadLogsStatus.FAILED;
    value = obj[key];
  } else if (keys.includes(UploadLogsStatus.COMPLETED)) {
    key = UploadLogsStatus.COMPLETED;
    value = obj[key];
  } else {
    [key] = keys;
    value = obj[key];
  }
  return {key, value};
};

export const calculatePercentage = (
  totalNumber: number,
  givenNumber: number
) => {
  if (totalNumber === 0) {
    return 0; // Handle division by zero scenario
  }
  const percentage = (givenNumber / totalNumber) * 100;
  return `${Math.floor(100 - percentage)}%`;
};

// Below function to get the word of the URL
export const getWordFromUrl = (url: string, positionFromEnd: number) => {
  return url.split('/')[url.split('/').length - positionFromEnd];
};

export const base64ToArrayBuffer = (base64: string) => {
  // Decode base64 to binary data
  const binaryString = window.atob(base64);

  // Convert binary string to ArrayBuffer
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const downloadFile = (
  base64Data: string,
  fileName: string,
  fileType: string
) => {
  const decodedArrayBuffer = base64ToArrayBuffer(base64Data);

  // Create a Blob from ArrayBuffer
  const blob = new Blob([decodedArrayBuffer], {
    type: fileType,
  });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create an anchor element for download
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;

  // Trigger the download
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up the URL and anchor
  URL.revokeObjectURL(url);
  document.body.removeChild(anchor);
};

// Below function to check the value of the particular key in object
export const isValueExist = (obj: {[key: string]: number}, key: string) => {
  const keys = Object.keys(obj);
  if (keys.includes(key)) {
    const value = obj[key];
    return value > 0;
  }
  return false;
};

// Below function to get the current date ====> Input: 10-01-2023 05:16:45 AM / Output: 10 Jan 2023 05:16 AM
export const formatDateWithTime = (
  date: string | undefined,
  hasTime = true
) => {
  if (!date || date.length <= 1) return null;
  const parsedDate = parse(date, 'dd-MM-yyyy hh:mm:ss aa', new Date());
  if (hasTime) return format(parsedDate, 'dd MMM yyyy, hh:mm aa');
  return format(parsedDate, 'dd MMM yyyy');
};

// Below function to get the current date ====> Input: 10-01-2023 / Output: 10 Jan 2023
export const formatDate = (date: string) => {
  if (date === '-' || !date || date.length <= 1) return '';
  if (date.includes('UTC')) {
    return format(new Date(date), 'dd MMM yyyy');
  }
  const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
  return format(parsedDate, 'dd MMM yyyy');
};

// Below function to convert the UTC date to IST format ====> Input: 01-04-2024 07:36:59 AM / Output: 01-04-2024 01:06:59 PM
export const UtcToIstFormat = (date: string) => {
  const parsedDate = parse(date, 'dd-MM-yyyy hh:mm:ss a', new Date());

  // Add 5:30 hours to convert to IST timezone
  const istAddHours = addHours(parsedDate, 5);
  const ISTDate = addMinutes(istAddHours, 30);

  // Format the IST date
  const ISTDateString = format(ISTDate, 'dd-MM-yyyy hh:mm a');
  return ISTDateString;
};

// Below function to get the current month and year in "01-2023" format
export const getCurrentMonthAndYear = () => {
  const currentDate = new Date();
  return format(currentDate, 'MM-yyyy');
};

// Below function to get the last month and year in "01-2023" format
export const getLastMonthAndYear = () => {
  const currentDate = new Date();
  const lastMonthDate = subMonths(currentDate, 1);
  return format(lastMonthDate, 'MM-yyyy');
};

// Below function to parse date. input => 01, 2023 output => Jan-2023
export const parseDateToShow = (
  month: number | string,
  year: number | string
) => {
  if (!month || !year) return null;
  const date = `${month}/${year}`;
  const parsedDate = parse(date, 'MM/yyyy', new Date());
  return format(parsedDate, 'MMM yyyy');
};

// Below function to get the date for compare. input => 12, 2021 output => Wed Dec 01 2021 00:00:00 GMT+0530 (India Standard Time)
export const getDateToCompare = (monthStr: string, yearStr: string) => {
  const month = parseInt(monthStr, 10);
  const year = parseInt(yearStr, 10);
  return new Date(year, month - 1);
};

// Below function to sort the data based on the string values on frontend
export const sortDataByProperty = <T>(
  dataArray: T[],
  property: keyof T,
  order: 'asc' | 'desc'
) => {
  const comparator = (a: T, b: T) => {
    if (a[property] < b[property]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[property] > b[property]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  };

  return [...dataArray].sort(comparator);
};

export const formatDataToRender = (data: IInputFilesProcessDetails[]) => {
  const [firstElement, secondElement, ...restOfData] = data;
  return [secondElement, firstElement, ...restOfData];
};

// Below function to search data in table every key
export const searchTableData = (data: [], query: string) => {
  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === 'string' || typeof value === 'number') &&
        value.toString().toLowerCase().includes(query.toLowerCase())
    )
  );
  return filteredData;
};

// Below function to search data in table based-on the given key
export const searchTableDataByKey = <T extends Record<string, string>>(
  data: T[],
  key: keyof T,
  query: string
) => {
  const filteredData = data.filter((item) =>
    item[key]?.toLowerCase().includes(query.toLowerCase())
  );
  return filteredData;
};

// Below function will return the data based-on query
export const getRenderData = (allData: [], searchData: [], query: string) => {
  return query.length > 0 ? searchData : allData;
};

// This type only for below function
interface SearchItem {
  [key: string]: {
    value: string | number;
    isValid: boolean;
    errorMessage: string | null;
  };
}

// Below function will return the data based-on query in nested object
export const searchInNestedArray = <T extends SearchItem>(
  data: T[],
  key: keyof T,
  query: string
): T[] => {
  return data.filter((item) =>
    item[key]?.value?.toString()?.toLowerCase().includes(query.toLowerCase())
  );
};

// GET applicant type for create or edit claim api
export const getValue = (findText: string, obj: ICommonKeyValue[]) => {
  const list = obj.find(
    (item) => item.key === findText || item.value === findText
  );
  return list || null; // Return the value if found, otherwise null.
};

// Below function to remove the undefined keys
export const removeUndefinedKeys = (obj: DDApiData | MoowrApiData) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== undefined && value !== '' && value !== null
    )
  );
};

// Below function to create a business option in User Management
export const getBusinessOptions = (data: IBusinessDetails[]) => {
  const businessNames = data?.map((item, index) => ({
    id: index + 1,
    title: item.organizationName,
    uniqueId: item.pan,
    isChecked: false,
  }));
  return businessNames;
};

// Below function to extracting the product to select and unselect based-on business pan in User Management
export const getProductsSelection = (
  data: IBusinessDetails[],
  value: boolean
) => {
  const products: ISubscribedProducts = {};
  data?.forEach((item: IBusinessDetails) => {
    const key = item.pan;
    products[key] = {};
    item.productDetails.forEach((product) => {
      products[key][product.productName] = value;
    });
  });

  return products;
};
