import {IIConProps} from '@common/interfaces';

export function UploadIcon(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 11.399 11.397'>
      <path
        id='solid_download'
        data-name='solid download'
        d='M4.808,0H6.589a.533.533,0,0,1,.535.534v3.74H9.076a.445.445,0,0,1,.314.759L6,8.421a.431.431,0,0,1-.608,0L2.006,5.033a.445.445,0,0,1,.314-.759H4.275V.534A.533.533,0,0,1,4.808,0ZM11.4,8.37v2.494a.533.533,0,0,1-.534.534H.534A.533.533,0,0,1,0,10.863V8.37a.533.533,0,0,1,.534-.533H3.8L4.891,8.928a1.14,1.14,0,0,0,1.616,0L7.6,7.837h3.266a.533.533,0,0,1,.536.533ZM8.637,10.33a.445.445,0,1,0-.445.445A.445.445,0,0,0,8.637,10.33Zm1.425,0a.445.445,0,1,0-.445.445.445.445,0,0,0,.445-.445Z'
        transform='translate(11.399 11.397) rotate(180)'
        fill={fill}
      />
    </svg>
  );
}

UploadIcon.defaultProps = {
  width: '11.4',
  height: '11.4',
  fill: '#262626',
};
