import {Path, ProfileTabs} from '@common/constants';
import {ManageSubscription, NotificationSettings} from '@shared/icons';

import {IDropDownValues} from './config';

interface IProductSettingsProps {
  [key: string]: {
    title: string;
    options: IDropDownValues[];
  };
}

const GENERAL = 'general';
const GENERAL_SETTINGS = 'General Settings';

const {NOTIFICATIONS_SETTINGS, MANAGE_SUBSCRIPTION} = ProfileTabs;

const settingsDropdown: IProductSettingsProps = {
  DASHBOARD: {
    title: GENERAL_SETTINGS,
    options: [
      {
        id: '5',
        value: NOTIFICATIONS_SETTINGS,
        path: `${Path.PROFILE}${Path.NOTIFICATIONS_SETTINGS}`,
        listTitle: GENERAL,
        icon: <NotificationSettings />,
      },
      {
        id: '7',
        value: MANAGE_SUBSCRIPTION,
        path: `${Path.PROFILE}${Path.MANAGE_SUBSCRIPTION}`,
        listTitle: GENERAL,
        icon: <ManageSubscription />,
      },
    ],
  },
};

export default settingsDropdown;
