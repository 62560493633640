import {IIConProps} from '@common/interfaces';

export function AccordionFile({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 9.009 12.011'>
      <path
        id='solid_file'
        data-name='solid file'
        d='M5.255,3.191V0H.563A.562.562,0,0,0,0,.563V11.448a.562.562,0,0,0,.563.563H8.445a.562.562,0,0,0,.563-.563V3.754H5.818A.565.565,0,0,1,5.255,3.191ZM9.009,2.86V3h-3V0h.143a.563.563,0,0,1,.4.164l2.3,2.3A.561.561,0,0,1,9.009,2.86Z'
        fill={fill}
      />
    </svg>
  );
}

AccordionFile.defaultProps = {
  fill: '#7A7A7A',
  width: '13',
  height: '12',
};
