import Footer from '@common/components/Footer';
import Header from '@common/components/Navbar';
import {AlertStatus, HIDE_HEADER_PATHS, Path} from '@common/constants';
import {alertActions} from '@core/api/store/alertReducer';
import Alert from '@shared/components/EximAlert/index';
import EximLoader from '@shared/components/EximLoader';
import {RootState, dispatch} from '@store';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import './App.scss';
import AppRoutes from './routes';
import './utils/main.scss';

export default function App() {
  const {
    loader: {isAppLoading},
    alert: {message, isOpen, alertType},
    header: {businessName},
    auth: {isLoggedIn},
  } = useSelector((state: RootState) => state);

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const btn = document.querySelector('.scroll-to-top') as HTMLButtonElement;
    if (btn) {
      setTimeout(() => btn.click(), 300);
    }
  }, [pathname]);

  const alertContent = () => (
    <div>
      {alertType === AlertStatus.SUCCESS ? (
        <span className='fw-7'>{`Success : `}</span>
      ) : null}
      {alertType === AlertStatus.WARNING ? (
        <span className='fw-7'>{`Warning : `}</span>
      ) : null}
      {alertType === AlertStatus.DANGER ? (
        <span className='fw-7'>{`Error : `}</span>
      ) : null}
      <span>{message}</span>
    </div>
  );

  if (isOpen) {
    setTimeout(() => {
      dispatch(alertActions.closeAlertMsg({}));
    }, 5000);
  }

  // INFO: Checking the page using pathname to add the over-flow hidden class and also remove the Navbar
  const isLoginPage =
    pathname === '/' ||
    pathname.includes(Path.LOGIN) ||
    pathname.includes(Path.FORGOT_PASSWORD) ||
    pathname.includes(Path.RESET_PASSWORD);

  const isDashboardPage = pathname.includes(Path.DASHBOARD);

  return (
    <>
      <ScrollToTop top={0} />
      <div
        className={`main-container ${isLoginPage && 'overflow-hidden'} ${
          isDashboardPage && 'dashboard-scroll-x'
        }`}>
        {/* INFO: The HIDE_HEADER_PATHS array includes the application pathname so on that time we don't return the Header component */}
        {HIDE_HEADER_PATHS.includes(pathname) ||
        (!isLoggedIn && isLoginPage) ? null : (
          <Header
            isLoggedIn={isLoggedIn}
            businessName={businessName}
            pageType='DASHBOARD'
          />
        )}
        <AppRoutes />
      </div>
      {/* INFO: No need to show the footer on Login and Registration page */}
      {isLoggedIn && <Footer showGstLogo={!isLoggedIn} />}

      {isAppLoading && (
        <div className='app-loader'>
          <EximLoader />
        </div>
      )}
      {isOpen && (
        <Alert
          closeFn={() => {
            dispatch(alertActions.closeAlertMsg({}));
          }}
          variant='filled'
          position='top'
          showIcon={false}
          content={alertContent()}
          isCloseVisible
          toastType={alertType}
          id={alertType}
          dataTestId='test-toast'
        />
      )}
    </>
  );
}
