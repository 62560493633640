import {IIConProps} from '../../common/interfaces';

export function AddFiles({fill, width, height}: IIConProps) {
  return (
    <svg
      id='add-files'
      data-name='add-files'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32.698 31.993'>
      <g id='Group_4726' data-name='Group 4726'>
        <g
          id='Group_4724'
          data-name='Group 4724'
          transform='translate(0 2.938)'>
          <g id='Group_4723' data-name='Group 4723'>
            <path
              id='Path_802'
              data-name='Path 802'
              d='M161.789,274.864v-6.341a4.481,4.481,0,0,0-4.48-4.481h-5.847a4.481,4.481,0,0,0-4.481,4.481v20.093a4.481,4.481,0,0,0,4.481,4.481h20.094a4.48,4.48,0,0,0,4.48-4.481v-5.142a4.48,4.48,0,0,0-4.48-4.48h-5.637A4.13,4.13,0,0,1,161.789,274.864Z'
              transform='translate(-146.981 -264.042)'
              fill='#5478b5'
            />
          </g>
        </g>
        <g id='Group_4725' data-name='Group 4725' transform='translate(17.492)'>
          <rect
            id='Rectangle_5435'
            data-name='Rectangle 5435'
            width='15.206'
            height='15.206'
            rx='3.334'
            fill={fill}
          />
        </g>
      </g>
      <g
        id='Group_4729'
        data-name='Group 4729'
        transform='translate(21.401 3.661)'>
        <g id='Group_4727' data-name='Group 4727' transform='translate(3.366)'>
          <path
            id='Path_803'
            data-name='Path 803'
            d='M172.368,272.735a.619.619,0,0,1-.62-.619v-6.732a.619.619,0,0,1,1.239,0v6.732A.619.619,0,0,1,172.368,272.735Z'
            transform='translate(-171.748 -264.765)'
            fill='#fff'
          />
        </g>
        <g
          id='Group_4728'
          data-name='Group 4728'
          transform='translate(0 3.366)'>
          <path
            id='Path_804'
            data-name='Path 804'
            d='M175.733,269.369H169a.619.619,0,1,1,0-1.238h6.731a.619.619,0,1,1,0,1.238Z'
            transform='translate(-168.382 -268.131)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}

AddFiles.defaultProps = {
  fill: '#384e6c',
  width: 16,
  height: 16,
};
