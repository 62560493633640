import {createSlice} from '@reduxjs/toolkit';

export interface ITxnDetails {
  txnId: string;
  txnName: string;
  txnType: string;
  fromDate: string;
  toDate: string;
  isTxnExist: boolean;
  transactionCalcStatus: string;
}

interface IInitialState {
  panNumber: string;
  iecNumber: string;
  businessName: string;
  invoiceTxnId: string;
  invoiceErrorFileId: string;
  invoicesCardActive: boolean;
  isLastTransactionInvalid: boolean;
  invoicesPeriod: {
    startPeriod: string;
    endPeriod: string;
  };
  currTransactionDetails: ITxnDetails;
  getTransactionHistory: () => void;
}

export const initialTxnDtls = {
  txnId: '',
  txnName: '',
  txnType: '',
  fromDate: '',
  toDate: '',
  isTxnExist: false,
  transactionCalcStatus: '',
};

const initialState: IInitialState = {
  panNumber: '',
  iecNumber: '',
  businessName: '',
  invoiceTxnId: '',
  invoiceErrorFileId: '',
  invoicesCardActive: true,
  isLastTransactionInvalid: false,
  invoicesPeriod: {
    startPeriod: '',
    endPeriod: '',
  },
  currTransactionDetails: initialTxnDtls,
  getTransactionHistory: () => {
    // INFO: set the function because need to call that function after discard the transaction to update the table data
  },
};

const moowr = createSlice({
  name: 'moowr',
  initialState,
  reducers: {
    setPanNumber(state, actions) {
      state.panNumber = actions.payload;
    },
    setIecNumber(state, actions) {
      state.iecNumber = actions.payload;
    },
    setBusinessName(state, actions) {
      state.businessName = actions.payload;
    },
    setInvoiceTxnId(state, actions) {
      state.invoiceTxnId = actions.payload;
    },
    setInvoiceErrorFileId(state, actions) {
      state.invoiceErrorFileId = actions.payload;
    },
    setInvoicesCardActive(state, actions) {
      state.invoicesCardActive = actions.payload;
    },
    setInvoicesPeriod(state, actions) {
      state.invoicesPeriod = actions.payload;
    },
    setIsLastTransactionInvalid(state, actions) {
      state.isLastTransactionInvalid = actions.payload;
    },
    setCurrTransactionDetails(state, actions) {
      state.currTransactionDetails = actions.payload;
    },
    setGetTransactionHistoryFunc(state, action) {
      state.getTransactionHistory = action.payload;
    },
    setTransactionCalcStatus(state, action) {
      state.currTransactionDetails.transactionCalcStatus = action.payload;
    },
  },
});

const moowrReducer = moowr.reducer;

export const moowrActions = moowr.actions;
export default moowrReducer;
