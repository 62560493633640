import {IIConProps} from '@common/interfaces';

export function SolidDownAngle({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 10.333'>
      <path
        id='solid_angle-down'
        data-name='solid angle-down'
        d='M7.1,162.731l-6.8-6.8a1.2,1.2,0,0,1,0-1.7l1.13-1.13a1.2,1.2,0,0,1,1.7,0l4.822,4.821,4.822-4.821a1.2,1.2,0,0,1,1.7,0l1.13,1.13a1.2,1.2,0,0,1,0,1.7l-6.8,6.8a1.189,1.189,0,0,1-1.691,0Z'
        transform='translate(0.05 -152.75)'
        fill={fill}
      />
    </svg>
  );
}

SolidDownAngle.defaultProps = {
  fill: '#002662',
  width: '16',
  height: '10.333',
};
