import CryptoJS from 'crypto-js';

import {REDUX_SECRET_KEY} from './constants';

export const encrypt = (text: string) => {
  const encryptedText = CryptoJS.AES.encrypt(
    text,
    REDUX_SECRET_KEY as string
  ).toString();
  return encryptedText;
};

export const decrypt = (encryptedText: string) => {
  const decryptedBytes = CryptoJS.AES.decrypt(
    encryptedText,
    REDUX_SECRET_KEY as string
  );
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
