import {IIConProps} from '@common/interfaces';

export function SquareCheckIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_2572'
        data-name='Group 2572'
        transform='translate(-933 -1650)'>
        <g id='Group_892' data-name='Group 892'>
          <g id='Group_854' data-name='Group 854' transform='translate(-70)'>
            <rect
              id='Rectangle_2126'
              data-name='Rectangle 2126'
              width='24'
              height='24'
              rx='5'
              transform='translate(1003 1650)'
              fill={fill}
            />
            <path
              id='solid_check'
              data-name='solid check'
              d='M4.835,74.637.209,70.4a.615.615,0,0,1,0-.923l1.006-.923a.758.758,0,0,1,1.006,0l3.116,2.856,6.675-6.118a.758.758,0,0,1,1.006,0l1.006.923a.615.615,0,0,1,0,.923l-8.184,7.5a.758.758,0,0,1-1.006,0Z'
              transform='translate(1008 1591.902)'
              fill='#fff'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

SquareCheckIcon.defaultProps = {
  fill: '#f15929',
  width: '24',
  height: '24',
};
