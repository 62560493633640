import {IIConProps} from '@common/interfaces';

export function InfoCircular({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_9905'
        data-name='Group 9905'
        transform='translate(-336 -324)'>
        <circle
          id='Ellipse_34'
          data-name='Ellipse 34'
          cx='12'
          cy='12'
          r='12'
          transform='translate(336 324)'
          fill={fill}
        />
        <path
          id='Path_1377'
          data-name='Path 1377'
          d='M2.284-7.523A1.21,1.21,0,0,1,1.848-7.6a1.21,1.21,0,0,1-.373-.224,1.147,1.147,0,0,1-.261-.336.9.9,0,0,1-.1-.417.905.905,0,0,1,.093-.411.966.966,0,0,1,.252-.317,1.161,1.161,0,0,1,.37-.2,1.409,1.409,0,0,1,.454-.072,1.592,1.592,0,0,1,.479.068,1.212,1.212,0,0,1,.38.193.881.881,0,0,1,.252.3.872.872,0,0,1,.09.4.992.992,0,0,1-.1.432,1.064,1.064,0,0,1-.264.348,1.288,1.288,0,0,1-.386.23A1.251,1.251,0,0,1,2.284-7.523ZM.361-.467l.828-.286a.441.441,0,0,0,.19-.112A.284.284,0,0,0,1.45-1.07V-5.034a.333.333,0,0,0-.078-.23.561.561,0,0,0-.233-.143L.38-5.7v-.448l2.931-.3V-1.07a.277.277,0,0,0,.075.205.474.474,0,0,0,.18.112l.8.286V0H.361Z'
          transform='translate(346 340)'
          fill='#ffffff'
        />
      </g>
    </svg>
  );
}
InfoCircular.defaultProps = {
  fill: '#c2e2e3',
  width: '24',
  height: '24',
};
