import {IIConProps} from '@common/interfaces';

export function DashboardIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 16.615'>
      <path
        id='noun-dashboard-4514192'
        d='M134.5,122.5a11.982,11.982,0,0,0-11.308,16,.936.936,0,0,0,.877.619h7.828a2.559,2.559,0,0,1-.166-.923,2.747,2.747,0,0,1,1.865-2.612.807.807,0,0,1-.019-.157v-5.538a.923.923,0,1,1,1.846,0v5.538a.806.806,0,0,1-.019.157,2.747,2.747,0,0,1,1.865,2.612,2.56,2.56,0,0,1-.166.923h7.828a.936.936,0,0,0,.877-.619,11.982,11.982,0,0,0-11.307-16Zm-7.384,12.923h-.923a.923.923,0,1,1,0-1.846h.923a.923.923,0,1,1,0,1.846Zm2.815-5.492a.906.906,0,0,1-.655.268.886.886,0,0,1-.646-.268l-.655-.655a.923.923,0,0,1,1.3-1.3l.655.655a.923.923,0,0,1,0,1.3Zm5.492-2.815a.923.923,0,1,1-1.846,0v-.923a.923.923,0,1,1,1.846,0Zm4.3,3.083a.907.907,0,0,1-.655-.268.923.923,0,0,1,0-1.3l.655-.655a.923.923,0,1,1,1.3,1.3l-.655.655a.886.886,0,0,1-.646.268Zm4.006,4.3a.923.923,0,0,1-.923.923h-.923a.923.923,0,1,1,0-1.846h.923a.923.923,0,0,1,.923.923Z'
        transform='translate(-122.496 -122.5)'
        fill={fill}
      />
    </svg>
  );
}

DashboardIcon.defaultProps = {
  fill: '#002662',
  width: '24',
  height: '16.615',
};
