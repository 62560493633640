import {IIConProps} from '@common/interfaces';

export function SolidUpIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10.837 6.455'>
      <path
        id='solid_angle-down'
        data-name='solid angle-down'
        d='M4.846.22.239,4.47a.707.707,0,0,0,0,1.059L1,6.235a.859.859,0,0,0,1.148,0L5.418,3.223,8.684,6.235a.859.859,0,0,0,1.148,0l.766-.706a.707.707,0,0,0,0-1.059L5.991.22a.854.854,0,0,0-1.145,0Z'
        fill={fill}
      />
    </svg>
  );
}

SolidUpIcon.defaultProps = {
  fill: '#002662',
  width: '12',
  height: '9',
};
