import {IIConProps} from '@common/interfaces';

export function DownloadIcon({fill, width, height}: IIConProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'>
      <g
        id='Group_13059'
        data-name='Group 13059'
        transform='translate(-1053 -1650)'>
        <g id='Group_894' data-name='Group 894'>
          <g id='Group_891' data-name='Group 891' transform='translate(50)'>
            <rect
              id='Rectangle_2126'
              data-name='Rectangle 2126'
              width='24'
              height='24'
              rx='5'
              transform='translate(1003 1650)'
              fill='#c2e2e3'
            />
          </g>
          <path
            id='solid_download'
            data-name='solid download'
            d='M5.623,0H7.706a.623.623,0,0,1,.626.625V5h2.283a.52.52,0,0,1,.367.887L7.021,9.848a.5.5,0,0,1-.711,0L2.345,5.886A.52.52,0,0,1,2.713,5H5V.625A.623.623,0,0,1,5.623,0ZM13.33,9.788V12.7a.623.623,0,0,1-.625.625H.625A.623.623,0,0,1,0,12.7V9.788a.623.623,0,0,1,.625-.623H4.444L5.719,10.44a1.333,1.333,0,0,0,1.89,0L8.884,9.164H12.7a.623.623,0,0,1,.627.623ZM10.1,12.08a.521.521,0,1,0-.521.521A.521.521,0,0,0,10.1,12.08Zm1.666,0a.521.521,0,1,0-.521.521.521.521,0,0,0,.521-.521Z'
            transform='translate(1058 1655)'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

DownloadIcon.defaultProps = {
  fill: '#002662',
  width: '24',
  height: '24',
};
