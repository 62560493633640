import {IIConProps} from '../../common/interfaces';

export function SolidCheckCircle(props: IIConProps) {
  const {fill, width, height} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10 10'>
      <path
        id='solid_check-circle'
        data-name='solid check-circle'
        d='M18,13a5,5,0,1,1-5-5A5,5,0,0,1,18,13Zm-5.578,2.647,3.71-3.71a.323.323,0,0,0,0-.456l-.456-.456a.323.323,0,0,0-.456,0l-3.025,3.025-1.412-1.412a.323.323,0,0,0-.456,0l-.456.456a.323.323,0,0,0,0,.456l2.1,2.1a.323.323,0,0,0,.456,0Z'
        transform='translate(-8 -8)'
        fill={fill}
      />
    </svg>
  );
}
SolidCheckCircle.defaultProps = {
  width: '10',
  height: '10',
  fill: '#fff',
};
