interface ResendEmailProps {
  fillSecondary?: string;
  fill?: string;
  width?: string | number;
  height?: string | number;
}

export function ResendEmail(props: ResendEmailProps) {
  const {fill, fillSecondary, width, height} = props;
  return (
    <svg
      id='Layer_2'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 89.39 96.7'>
      <g id='Layer_1-2'>
        <g>
          <g>
            <polygon
              fill={fillSecondary}
              points='7.85 29.78 44.69 55.54 81.54 29.78 7.85 29.78'
            />
            <g>
              <rect
                fill='#eeefef'
                x='15.73'
                width='57.94'
                height='67.41'
                rx='2.48'
                ry='2.48'
              />
              <rect
                fill='#cecfcf'
                x='21.63'
                y='4.7'
                width='23.01'
                height='10.78'
                rx='1.49'
                ry='1.49'
              />
              <rect
                fill='#cecfcf'
                x='47.66'
                y='6.15'
                width='20.1'
                height='1.53'
              />
              <rect
                fill='#cecfcf'
                x='47.66'
                y='9.65'
                width='20.1'
                height='1.53'
              />
              <rect
                fill='#cecfcf'
                x='47.66'
                y='13.15'
                width='20.1'
                height='1.53'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='19.56'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='23.53'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='27.51'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='31.48'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='35.46'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='39.44'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='43.41'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='47.39'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='51.36'
                width='46.13'
                height='1.39'
              />
              <rect
                fill='#e5e7e7'
                x='21.63'
                y='55.34'
                width='46.13'
                height='1.39'
              />
            </g>
            <g>
              <polygon
                fill={fill}
                points='81.54 29.78 44.69 55.54 81.54 81.3 81.54 29.78'
              />
              <polygon
                fill={fill}
                points='7.85 81.3 44.69 55.54 7.85 29.78 7.85 81.3'
              />
            </g>
            <polygon
              fill={fill}
              points='81.54 81.3 44.69 55.54 7.85 81.3 81.54 81.3'
            />
          </g>
          <g>
            <rect fill='#e5e7e7' y='88.14' width='48.84' height='1.91' />
            <rect
              fill='#e5e7e7'
              x='33.97'
              y='94.79'
              width='38.03'
              height='1.91'
            />
            <rect
              fill='#e5e7e7'
              x='52.61'
              y='88.14'
              width='7.78'
              height='1.91'
            />
            <rect
              fill='#e5e7e7'
              x='64.61'
              y='88.14'
              width='24.78'
              height='1.91'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

ResendEmail.defaultProps = {
  width: '100',
  height: '100',
  fillSecondary: '#34af7f',
  fill: '#2ec48b',
};

export default ResendEmail;
