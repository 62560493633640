import {IIConProps} from '@common/interfaces';

export function SolidRightTriangle({fill, width, height}: IIConProps) {
  return (
    <svg
      id='Group_13483'
      data-name='Group 13483'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 8 15'>
      <path
        id='Polygon_15'
        data-name='Polygon 15'
        d='M6.77.778a1,1,0,0,1,1.459,0l5.192,5.538A1,1,0,0,1,12.692,8H2.308a1,1,0,0,1-.73-1.684Z'
        transform='translate(8) rotate(90)'
        fill={fill}
      />
    </svg>
  );
}

SolidRightTriangle.defaultProps = {
  fill: '#002662',
  width: '12',
  height: '16',
};
